

<mat-toolbar class="fix-nav" [color]="ThemeColor" style="padding:10px">
    <div style="height:100px">
    
  <div class="nav-brand" title="{{subTitle}}" >
        <!-- {{title}} -->
        <img id="topNavLogo" [src]="LogoImage" class="circle z-depth-2 responsive-img activator" alt="{{title}}" (click)="goToLink()">
    </div>
    <div style="display:flex;flex-direction: row; justify-content: start;">
        
    <div id='OrgDropDownContainer' class="nav-org-list">
        <app-custom-select-autocomplete [isTopNav]="true" [dataType]="dataType"  [filters]="selectedDatafilters"
         #customselectautocomplete [value]="selectedOrganization" [dataTypeDisplay]="dataTypeDisplay"
          [placeholder]="'Select ' + dataTypeDisplay" [canDelete]="false"
           (onSelectReturn)="setSelectedOrganization($event,true)" needHide ="true" ></app-custom-select-autocomplete>
      </div>
      <div class="nav-org-list" *ngIf="selectedOrganization" >
        <app-custom-select-autocomplete [isTopNav]="true" dataType="app" [filters]="selectedAppDatafilters"
            #customappselectautocomplete [value]="selectedApp" dataTypeDisplay="Application"
            placeholder="Select Application" [canDelete]="false" needHide ="true"
            (onSelectReturn)="setSelectedApp($event)" ></app-custom-select-autocomplete>
    </div>  
      <div class="nav-org-list" *ngIf="selectedApp">
        <app-custom-select-autocomplete [isTopNav]="true" dataType="location" [filters]="selectedLocationDatafilters"
            #customlocationselectautocomplete [value]="selectedLocation" dataTypeDisplay="Location"
            placeholder="Select Location" [canDelete]="false" needHide ="true"
            (onSelectReturn)="setSelectedLocation($event,true)"></app-custom-select-autocomplete>
    </div>

    <div class="nav-org-list" *ngIf="selectedLocation">
        <app-custom-select-autocomplete [isTopNav]="true" dataType="physician" [filters]="selectedPhysicianDatafilters"
            #customselectautocomplete [value]="selectedPhysician" dataTypeDisplay="Physician"
            placeholder="Select Physician" [canDelete]="false"
            (onSelectReturn)="setSelectedPhysician($event)"></app-custom-select-autocomplete>
    </div>
</div>
</div>
    <span class="nav-spacer"></span>
  
    <div><span class="date">{{date | date : "EEEE MMMM d, y" }}</span></div>

    <!-- <div id='consultationImgContainer' class="nav-brand-consult" title="{{subTitle}}" style="display:none">
        
        <img id="ConsultationLogo" [src]="consultationImage" class="circle z-depth-2 responsive-img activator" alt="{{title}}" (click)="goToConsultationLink()">
    </div> -->
    <button mat-icon-button 
    class="notifications-menu-button" [class.has-new]="hasNew">
    <mat-icon>notifications</mat-icon>
</button>
<mat-menu #notificationsMenu="matMenu">
    <div class="notifications-top-nav-box">

        <div *ngIf="!notificationsLoaded" style="text-align: center;">
            <mat-spinner diameter="30" style="margin: auto;"></mat-spinner>
        </div>

        <div *ngIf="!notificationsList.length && notificationsLoaded">
            <p style="text-align: center;">No notifications yet</p>
        </div>

        <span *ngIf="notificationsList.length && notificationsLoaded">
            <div *ngFor="let item of notificationsList">
                <button mat-menu-item [class.notification-badge]="item.viewed == false"
                    (click)="viewNotification(item)" style="font-size: 14px;">{{item.title}}</button>
            </div>
        </span>
    </div>
    <div>
        <button mat-menu-item style="width:35%; float: left; padding: 0px; margin-right: 0px; text-align: center;line-height: 35px; height: 35px;"
            (click)="moveToNotificationsPage()">View all</button>
        <button mat-menu-item color="primary-new"
            style="width:Calc(65% - 5px); padding: 0px; float: left; text-align: center; background-color: #32A5D7; color: #ffffff;line-height: 35px; height: 35px;"
            (click)="markAllAsRead()">Mark all as read</button>
    </div>
</mat-menu>

<div class="vl"></div>

<div _ngcontent-xbt-c146="" class="divider-top-nav"></div>
<div class="profile-menu" [matMenuTriggerFor]="profileMenu">

    <button mat-icon-button class="icon-button" >
        <img
        class="icon"
        [src]="pictureLink"
      />
    </button>
    <mat-menu #profileMenu="matMenu">
        <div class="profile-top-nav-box">
                <button mat-menu-item 
                [routerLink]="[]" (click)="viewProfile()" style="font-size: 14px;">Profile
                </button>

                <button *ngIf="userType === 'default'" mat-menu-item 
                    (click)="onLoggedout()" style="font-size: 14px;">Logout
                </button>
        </div>
    </mat-menu>
</div>

</mat-toolbar>
