import { Component, Inject, OnInit, ChangeDetectorRef, ViewChildren, QueryList, ElementRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { urlSafeBase64Encoding, isValidHttpUrl, getOtherImage, deepEqual } from '../../../shared/helpers';
import { FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { fromEvent, BehaviorSubject, merge, Subscription } from 'rxjs';
import { ErrorStateMatcher } from '@angular/material/core';

import { CustomSelectDialogComponent } from '../../../shared/components/custom-select-dialog/custom-select-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { Moment } from 'moment';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { ModalGalleryDialogComponent } from '../../../shared/components/custom-gallery-dialog/custom-gallery-dialog.component';
import { RecurrenceDialogComponent } from '../../../shared/modules/calendar/recurrence-dialog/recurrence-dialog.component';
import { ConfirmEntityDialogComponent } from '../modals/confirm-entity-dialog/confirm-entity-dialog.component';
declare var rrule: any;

export class MyDialogErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export interface DialogData {
  dataType: string;
  dataTypeTitle: string;
  dataTypeSingleTitle: string;
  title: string;
  data: any;
  action: string;
  modalSetting: any;
  confirmData: any;
}

@Component({
  selector: 'app-dialog-modal',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.scss']
})
export class ModalDialogComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public errorMessage: string = '';
  public selectedUser: any;
  public selectedUserSubscription: any = undefined;
  private organization: any = undefined;
  public settingOrgObject = undefined;
  public isPasswordOrgRequired: boolean = false;
  public hasRolesOrgRequired: boolean = false;
  public adminId: string = "";
  public vertical = environment.orgType;
  public isAdmin: boolean = false;
  public isSuperAdmin: boolean = false;
  public loading: boolean = false;
  public sendEmail: boolean = true;
  public hasFormErrors: boolean = false;
  public esMatcher = new MyDialogErrorStateMatcher();
  public isSubmitted: boolean = false;
  public controllerData = {};
  public metaFieldSetting = undefined;
  public originalData = undefined;
  public oldData = undefined;
  public age = 0;
  public ruleText = '';
  public defaultRuleData: any = {
    repeats: "daily",
    interval: 1,
    end: "after",
    count: 1
  };
  public orderOfExhibit: number[] = [];
  public allowedExtensions: string[] = ['jpeg', 'jpg', 'png', 'gif'];
  public allowedAcceptExtensions: string = 'image/jpg,image/jpeg,image/png,image/gif';
  @ViewChild('fileselect') fileselect: ElementRef;
  constructor(private translate: TranslateService, private changeDetectorRefs: ChangeDetectorRef,
    private requestService: RequestService, public dialog: MatDialog,
    private layoutUtilsService: LayoutUtilsService, private loaderService: LoaderService,
    public dialogRef: MatDialogRef<ModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    //console.log('DialogData', data);

    if (!this.data.dataTypeSingleTitle) {
      this.data.dataTypeSingleTitle = this.data.dataTypeTitle;
    }
  }
  ngOnInit() {
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.isAdmin = this.requestService.isUserRoleAdmin();
          this.isSuperAdmin = this.requestService.isUserRoleSuperAdmin();
          this.buildSetting();
        }
      })
    );
    this.subscriptions.push(
      this.requestService.pageOrganization.subscribe((data) => {
        if (data) {
          this.organization = data;
          // if (data.hasOwnProperty('settings')) {
          //   this.settingOrgObject = data.settings;
          // }
          if (data.hasOwnProperty('stellarPasswordRequired')) {
            this.isPasswordOrgRequired = data.stellarPasswordRequired;
          }
          this.hasRolesOrgRequired = (data.roleCount && data.roleCount > 0);
          let exhibitorTotal = 1;
          if (this.organization.exhibitorsCount) {
            exhibitorTotal = this.organization.exhibitorsCount
          }
          this.orderOfExhibit = Array(exhibitorTotal).fill(1).map((x, i) => i + 1);
        }
      })
    );
  }
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  public checkDisabled(value) {
    if (this.settingOrgObject && this.settingOrgObject.web && this.settingOrgObject.web.topBar) {
      if (this.settingOrgObject.web.topBar.disableSchedule && value === 'agenda') {
        return true;
      } else if (this.settingOrgObject.web.topBar.disableExhibitor && value === 'sponsors') {
        return true;
      } else if (this.settingOrgObject.web.topBar.disableResource && value === 'resources') {
        return true;
      } else if (this.settingOrgObject.web.topBar.disablePoster && value === 'posters') {
        return true;
      } else if (this.settingOrgObject.web.topBar.disableDirectory && value === 'book') {
        return true;
      }
    }
    return false;
  }
  private buildSetting() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.data.modalSetting.fields = [];

      let orgId = undefined;
      let lang = undefined;
      if (this.data.dataType === 'user') {
        orgId = this.requestService.orgId.getValue();
        lang = '';
      }
      this.requestService.getMetaData(this.data.dataType, undefined, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          let newFields = data.results.fields;
          this.metaFieldSetting = this.buildMetaSetting(data.results, parent = undefined);
          let idx = 0;
          if (this.data.modalSetting.hasOwnProperty('customSettings')) {
            for (let fld of newFields) {
              if (this.data.modalSetting.customSettings.hasOwnProperty(fld.name)) {
                if (this.data.modalSetting.customSettings[fld.name].hasOwnProperty('visible')) {
                  newFields[idx]['visible'] = this.data.modalSetting.customSettings[fld.name].visible;
                }
                if (this.data.modalSetting.customSettings[fld.name].hasOwnProperty('multiple')) {
                  newFields[idx]['multiple'] = this.data.modalSetting.customSettings[fld.name].multiple;
                }
                if (this.data.modalSetting.customSettings[fld.name].hasOwnProperty('enumDisabled')) {
                  newFields[idx]['enumDisabled'] = this.data.modalSetting.customSettings[fld.name].enumDisabled;
                }
                if (this.data.modalSetting.customSettings[fld.name].hasOwnProperty('disabled')) {
                  newFields[idx]['disabled'] = this.data.modalSetting.customSettings[fld.name].disabled;
                }
                if (this.data.modalSetting.customSettings[fld.name].hasOwnProperty('controller')) {
                  newFields[idx]['controller'] = this.data.modalSetting.customSettings[fld.name].controller;
                }
                if (this.data.modalSetting.customSettings[fld.name].hasOwnProperty('nullable')) {
                  newFields[idx]['nullable'] = this.data.modalSetting.customSettings[fld.name].nullable;
                }
                if (this.data.modalSetting.customSettings[fld.name].hasOwnProperty('control')) {
                  newFields[idx]['control'] = this.data.modalSetting.customSettings[fld.name].control;
                  newFields[idx]['filters'] = this.data.modalSetting.customSettings[fld.name].filters;
                  newFields[idx]['nullable'] = this.data.modalSetting.customSettings[fld.name].required;
                  if (this.data.modalSetting.customSettings[fld.name].hasOwnProperty('blueprint')) {
                    newFields[idx]['blueprint'] = this.data.modalSetting.customSettings[fld.name].blueprint;
                  }
                }
              } else if (this.hasRolesOrgRequired && ((this.data.dataType === 'session' && fld.name === 'openbyroles') || (this.data.dataType === 'user' && fld.name === 'role'))) {
                newFields[idx]['visible'] = true;
              }
              idx++;
            }
          }
          // console.log(newFields);
          this.data.modalSetting.fields = newFields;
          if (this.data.data.hasOwnProperty('_id')) {
            this.loadData();
          } else {
            this.data.data = this.getEmptyObject();
            this.cleanFieldsMeta();
          }
        } else {
          console.log(this.translate.instant('Something is Wrong'));
        }
      }, orgId);
    }
  }
  buildMetaSetting(data, parent = undefined) {
    let dataObject = {};
    // let tabObject = [];
    for (let col of data.fields) {
      if (((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') || (col['name'] === 'age' && this.data.dataType === 'user')) {
        if (parent) {
          col['inputName'] = parent + col['name'];
        }
        dataObject[col.name] = col;
      } else if (col.type === 'object') {
        dataObject[col.name] = this.buildMetaSetting(col);
      }
      else if (col.type === 'table') {
        dataObject[col.name] = col;
      }
    }
    return dataObject;
  }
  public loadData() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let dataId = this.data.data['_id'];
      if (this.data.modalSetting.hasOwnProperty('useOrgId')) {
        dataId = dataId + '/' + this.requestService.orgId;
      }
      this.requestService.getSingleData(this.data.dataType, dataId, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        if (data) {

          this.data.data = this.getCleanDataObject(data.results);
          
          if (this.data.data.hasOwnProperty('dob') && this.data.dataType === 'user') {
            let birthdate = new Date(this.data.data['dob']);
            this.calculateAge(birthdate);
          }
          if (this.data.dataType === 'schedule/event') {
            if (this.data.data.isRecurrence && this.data.data.recurrence) {
              this.setRuleText();
            }

          }

          this.originalData = JSON.parse(JSON.stringify(this.data.data));

          this.oldData = JSON.parse(JSON.stringify(this.data.data)); 
          this.oldData = this.getCleanObject(this.oldData);

          this.cleanFieldsMeta();          

        }
        this.loading = false;
      });
    }
  }
  private getCleanDataObject(currentObject) {
    let newObj = currentObject;
    for (let col of this.data.modalSetting.fields) {
      if ((col.type === 'datetime' || col.type === 'date') && newObj.hasOwnProperty(col.name)) {
        if (col.name === 'dob') {
          newObj[col.name] = moment(newObj[col.name]).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        } else {
          newObj[col.name] = moment.utc(newObj[col.name]).local().format('YYYY-MM-DDTHH:mm:ss');
        }
      } else if (col.type === 'tags' && !newObj.hasOwnProperty(col.name)) {
        newObj[col.name] = [];
      } else if (col.type === 'boolean' && col.name === 'isPasswordRequired' && newObj['isSuperAdmin']) {
        newObj[col.name] = true;
      } else if (this.data.dataType === 'session' && col.type === 'boolean' && newObj['isKeepActive'] && (col.name === 'autoend' || col.name === 'autostart')) {
        newObj[col.name] = false;
      } else if (col.type === 'enum' && !col.multiple && col.name === 'meetingType') {
        newObj[col.name] = newObj[col.name][0];
      }
      if (newObj[col.name] === null || newObj[col.name] === undefined) {
        if (col.type === 'reference') {
          if (col.name === 'role' || col.name === 'openbyroles') {
            newObj[col.name] = [{ _id: '', name: '' }];
          }
        } else if (col.type === 'boolean') {
          newObj[col.name] = false;
        } else if (col.type === 'color') {
          newObj[col.name] = '#ffffff';
        } else if (col.type === 'picturearray') {
          newObj[col.name] = [];
        } else if (col.type === 'array') {
          newObj[col.name] = [];
        }
        else if (col.type === 'maparray' || col.type === 'tags' || (col.type === 'enum' && col.multiple)) {
          newObj[col.name] = [];
        } else if (col.type === 'json') {
          newObj[col.name] = {};
        } else if (col.type === 'datetime') {
          newObj[col.name] = moment.utc().local().format('YYYY-MM-DDTHH:mm:ss');
        } else {
          newObj[col.name] = '';
        }
      } else if (col.type === 'number' && (newObj[col.name] === null || newObj[col.name] === '')) {
        newObj[col.name] = undefined;
      }
      if (col.type === 'picturearray') {
        newObj[col.name] = newObj[col.name].map((itm) => {
          itm['thumbnail'] = getOtherImage('', itm.url, '_thumbnail');
          return itm;
        });
      }
    }
    return newObj;
  }

  public getDateAtStamp(dt): string {
    let tempDate = new Date(dt);
    let datePipe = new DatePipe('en-US');
    let setDob = datePipe.transform(tempDate, 'yyyy-MM-ddTHH:mm:ss.SSS[Z]');
    return setDob;
  }
  public setBooleanAttribute(name, val) {
    if (val && name === 'isKeepActive') {
      this.data.data['autoend'] = false;
      this.data.data['autostart'] = false;
    }
    if (this.data.dataType === 'schedule/event') {
      if (name === 'isRecurrence') {
        if (val) {
          this.data.data['recurrence'] = JSON.parse(JSON.stringify(this.defaultRuleData));
          this.data.data['recurrence']['startdate'] = this.data.data.startdate;
          this.data.data['recurrence']['enddate'] = this.data.data.enddate;

          this.setRuleText();
        }
        this.cleanFieldsMeta();
      }
    }
  }
  public setSuperAdminAttribute(val) {
    if (val) {
      this.data.data['isPasswordRequired'] = true;
    }
  }
  cleanFieldsMeta() {
    // let newFields = JSON.parse(JSON.stringify(this.data.modalSetting.fields));
    if (this.data.dataType === 'schedule/event') {
      for (let col of this.data.modalSetting.fields) {

        if (col.name === 'enddate') {
          const originalDate = moment(this.data.data['startdate']).local();
          const targetDate = moment(this.data.data['enddate']).local();
          const day = originalDate.date();
          const month = originalDate.month();
          const year = originalDate.year();
          targetDate.set('date', day);
          targetDate.set('month', month);
          targetDate.set('year', year);
          this.data.data['enddate'] = targetDate.local().format('YYYY-MM-DDTHH:mm:ss');
        } else if (col.name === 'startdate') {
          if (this.data.data['isRecurrence'] && this.data.data.recurrence) {
            // let startdate = new Date(this.data.data.startdate);
            // let startdateUTC = new Date(Date.UTC(startdate.getFullYear(), startdate.getMonth(), startdate.getDate(), startdate.getHours(), startdate.getMinutes(), 0));
            // this.data.data.recurrence['startdate'] = startdate.toISOString();
            this.data.data.recurrence['startdate'] = moment(this.data.data.startdate).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
            if (this.data.data.recurrence.byweekday) {
              let originalStartDate = moment(this.data.data['startdate']);
              let weekOfDay = originalStartDate.format('dd').toUpperCase();
              if (!this.data.data.recurrence.byweekday.includes(weekOfDay)) {
                this.data.data.recurrence.byweekday.push(weekOfDay);
              }
            }
            this.setRuleText();
          }
        }
      }
    }
    // this.data.modalSetting.fields = JSON.parse(JSON.stringify(newFields));

  }

  updateFieldsMeta(id, targetId, targetValue) {
    let newFields = JSON.parse(JSON.stringify(this.data.modalSetting.fields));
    for (let col of newFields) {
      if (col.name === id) {
        col[targetId] = targetValue;
      }
    }
    this.data.modalSetting.fields = JSON.parse(JSON.stringify(newFields));
  }
  clearSimpleValue(e, id): any {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setAttribute(id, undefined);
  }
  public setAttribute(id, val) {
    this.data.data[id] = val;

  }
  public setAttributeBoolean(id, val) {
    this.data.data[id] = JSON.parse(val);
  }
  public setReferenceAttribute(parentId, id, val) {
    if (val.hasOwnProperty('_id')) {
      this.data.data[parentId][id] = val._id;
      if (val.hasOwnProperty('name')) {
        this.data.data[parentId]['name'] = val.name;
      }
      if (val.hasOwnProperty('text')) {
        this.data.data[parentId]['name'] = val.text;
      }
    } else {
      this.data.data[parentId][id] = val;
    }
  }
  public setReferenceControllerAttribute(parentId, id, val) {
    this.data.data[parentId] = val;
    this.loadControllerData(val._id, parentId);

  }
  public setMultipleReferenceAttribute(id, val) {
    this.data.data[id] = val;
  }
  public setRoleReferenceAttribute(parentId, val) {
    // if (val !== this.adminId) {
    //   this.data.data['isSuperAdmin'] = false;
    // }
    this.data.data[parentId] = [{ _id: val._id, name: val.name }];
    if (parentId === 'resources' && this.data.dataType === 'user') {
      this.cleanFieldsMeta();
    }
  }
  public setRoleAttribute(parentId, val) {
    this.data.data[parentId] = [{ _id: val._id, name: val.name }];
  }
  public setDateTimeAttribute(id, val) {
    // let dtstart = moment(val).utc().toDate();
  
    this.data.data[id] = moment(val).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    if ((id === 'startdate' || id === 'enddate') && this.data.dataType === 'schedule/event') {
      this.cleanFieldsMeta();
    }

  }

  public setStartEndDateTimeAttribute(data) {
    // console.log(data);
    for (let val of data) {
      if (val.id === 'startdate') {
        this.data.data['startdate'] = moment(val.value).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      } else if (val.id === 'enddate') {
        this.data.data['enddate'] = moment(val.value).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      } else if (val.id === 'allday') {
        this.data.data['allday'] = JSON.parse(val.value);
      }
    }
    this.cleanFieldsMeta();
    // console.log(this.data.data);
  }
  public calculateAge(birthdate) {
    if (birthdate) {
      var timeDiff = Math.abs(Date.now() - birthdate);
      //Used Math.floor instead of Math.ceil
      //so 26 years and 140 days would be considered as 26, not 27.
      this.age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
    }
  }
  public setDateAttribute(id, val) {
    // console.log('setDateAttribute', id, val.toISOString());
    try {
      this.data.data[id] = val.toISOString();
      if (id === 'dob' && this.data.dataType === 'user') {
        this.calculateAge(val);
      }
    } catch (e) {
      // error
      //console.log('setDateAttribute', e);
    }
  }
  public loadControllerData(dataId, controller) {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getSingleData(this.data.dataType, dataId, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          this.data.data = this.updateObject(this.data.data, controller, data.results);
        }
        this.loading = false;
      });
    }
  }
  private updateObject(data, controller, newData) {
    let newObj = data;
    for (let col of this.data.modalSetting.fields) {
      if (col.hasOwnProperty('controller') && col.controller === controller) {
        newObj[col.name] = newData[col.name];
      }
    }
    return newObj;
  }
  private getCleanObject(data) {
    let newObj: any = { _id: data._id };
    for (let col of this.data.modalSetting.fields) {
      if ((col.editable || !col.generated) && col.type !== 'action') {
        if (col.dataType === 'password') {
          newObj[col.name] = urlSafeBase64Encoding(data[col.name]);
        } else if (col.type === 'reference') {
          if (col.reference.kind === 'multiple') {
            if (data[col.name] && data[col.name].length > 0) {
              if (col.name === 'resources' || col.name === 'role') {
                data[col.name] = data[col.name].splice(0, 1);
                newObj[col.name] = data[col.name];
              } else {
                newObj[col.name] = data[col.name];
              }
            }
            if (col.name === 'openbyroles' && data[col.name] && data[col.name].length === 0) {
              newObj[col.name] = [];
            }
          } else {
            if (data[col.name] !== '')
              newObj[col.name] = data[col.name];
          }
        } else if (col.type === 'enum') {
          if (col.multiple) {
            if (data.hasOwnProperty(col.name) && data[col.name].length === 0) {
              newObj[col.name] = [];
            }
            else {
              newObj[col.name] = data[col.name];
            }
          }
          else {
            if (col.name === 'meetingType') {
              newObj[col.name] = [];
              if (data[col.name].length != '') {
                let temp = data[col.name];
                newObj[col.name].push(temp);
              }

            }
            else {
              newObj[col.name] = data[col.name];
            }

          }
        }


        else if (col.type === 'datetime' || col.type === 'date') {
          if (col.name === 'dob') {
            if (data[col.name] !== '') {
              newObj[col.name] = moment(data[col.name]).format('YYYY-MM-DD') + 'T00:00:00';
            }
          } else {
            newObj[col.name] = moment(data[col.name]).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
          }
        } else {
          newObj[col.name] = data[col.name];
        }
        // if (this.data.modalSetting.hasOwnProperty('customSettings')) {
        //   if (this.data.modalSetting.customSettings.hasOwnProperty(col.name)) {
        //     newObj[col.name] = this.data.modalSetting.customSettings[col.name].value;
        //   }
        // }
      }
    }
    if (this.data.dataType === 'user') {
      if (newObj.hasOwnProperty('_id') && newObj._id) {
        // clean not needed in user to update data
        delete newObj['organizationId'];
        delete newObj['appId'];
        delete newObj['locationId'];
        delete newObj['sessions'];
        // if (newObj.isSuperAdmin) {
        //   delete newObj['resources'];
        // }
      } else {
        if (newObj.hasOwnProperty('listedInTheDirectory')) {
          // On create set isTextChat and isVideoChat to true if listedInTheDirectory is set yes
          if (newObj['listedInTheDirectory'] === 'yes') {
            newObj['isTextChat'] = true;
            newObj['isVideoChat'] = true;
          } else {
            newObj['isTextChat'] = false;
            newObj['isVideoChat'] = false;
          }
        }
        // sendEmail on create is sent for Send registration email
        newObj['sendEmail'] = this.sendEmail;
      }
    }
    return newObj;
  }
  private validateObject(data: any) {
    for (let col of this.data.modalSetting.fields) {
      if ((!col.nullable && !col.generated) && col.type !== 'action' && col.name !== 'privacyterm' && (col.visible || (col.admin && this.isAdmin) || (col.superadmin && this.isSuperAdmin))) {
        if (col.type === 'reference') {
          if (col.reference.kind === 'multiple') {
            // console.log('col.name', col.name, data[col.name] );
            if (data && data[col.name] && data[col.name].length === 0) {
              return false;
            }

          } else {
            if (data && data[col.name] && data[col.name]['_id'] === '') {
              // console.log('col.name', col.name, data[col.name] );
              return false;
            }
          }
        } else if (col.type === 'tags') {
          if (data && (data[col.name].length === 0 || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        } else if (col.type === 'picturearray') {
          if (data && (data[col.name].length === 0 || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        } else if (col.type === 'email') {
          if (data && (data[col.name].length === 0 || data[col.name] === undefined || !this.isEmailValid(data[col.name]))) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        } else if (col.type === 'url') {
          if (data && (data[col.name].length === 0 || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        } else if (col.type === 'password') {
          if (data && ((data[col.name] === '' || data[col.name] === undefined) || (data[col.name] && data[col.name].length < 8 && data[col.name].length > 64))) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        } else if (col.type === 'objectjson') {
          if ((col.name === 'attendees') && this.data.dataType === 'event') {
            if (data && data.hasOwnProperty(col.name) && (data[col.name].length === 0 || data[col.name] === '')) {
              return false;
            }
          }
        } if (col.type === 'enum' && col.name === 'meetingType') {
          if (data && data.hasOwnProperty(col.name) && (data[col.name].length === 0 || data[col.name] === '')) {
            return false;
          }
        }
        else {
          if (data && (data[col.name] === '' || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        }
      }
    }
    return true;
  }
  private validateSubscriptionLogic(type, data, orgSubscriptionData) {
    if (orgSubscriptionData.subscription_status === 'paid') {
      if (type === 'create') {
        if (data['type'] === '3' && orgSubscriptionData.number_of_remaining_exhibit_rooms <= 0) {
          this.layoutUtilsService.showNotification(this.translate.instant('You cannot add more exhibit rooms, contact your sales'), this.translate.instant('Dismiss'));
          return false;
        }
        // else if (data['type'] !== '3' && orgSubscriptionData.number_of_remaining_advanced_rooms <= 0) {
        //   this.layoutUtilsService.showNotification(this.translate.instant('You cannot add more rooms, contact your sales'), this.translate.instant('Dismiss'));
        //   return false;
        // }
      } else if (type === 'edit') {
        if (this.originalData['type'] !== data['type'] && data['type'] === '3' && orgSubscriptionData.number_of_remaining_exhibit_rooms <= 0) {
          this.layoutUtilsService.showNotification(this.translate.instant('You cannot add more exhibit rooms, contact your sales'), this.translate.instant('Dismiss'));
          return false;
        }
        // else if (this.originalData['type'] !== data['type'] && data['type'] !== '3' && orgSubscriptionData.number_of_remaining_advanced_rooms <= 0) {
        //   this.layoutUtilsService.showNotification(this.translate.instant('You cannot add more rooms, contact your sales'), this.translate.instant('Dismiss'));
        //   return false;
        // }
      }
    }
    return true;
  }
  private validateLogic(data) {
    for (let col of this.data.modalSetting.fields) {
      if (!col.generated && col.type !== 'action' && col.name !== 'privacyterm' && (col.visible || (col.admin && this.isAdmin) || (col.superadmin && this.isSuperAdmin))) {
        if (col.hasOwnProperty('charlimit')) {
          if (data && data[col.name] && data[col.name].length > col.charlimit) {
            return this.translate.instant(col.displayName + ' should have a maximum of ' + col.charlimit + ' characters');
          }
        } else {
          if (col.type === 'textarea') {
            if (data && data[col.name] && data[col.name].length > 1000) {
              return this.translate.instant(col.displayName + ' should have a maximum of 1000 characters');
            }
          }
        }
        if (col.type === 'picturearray') {
          if (data && data[col.name] && data[col.name].length > 0) {
            let idx = 0;
            for (let pc of data[col.name]) {
              idx = idx + 1;
              if (!pc.link || pc.link === '') {
                if (pc.type === 'link') {
                  return this.translate.instant('Fill in the URL link for picture' + idx);
                } else if (pc.type === 'none') {
                  // do nothing
                } else {
                  return this.translate.instant('Select the correct ' + pc['type'] + ' to open');
                }
              }
              if (pc.type === 'link') {
                if (!isValidHttpUrl(pc.link)) {
                  return this.translate.instant('Fill in the correct URL link format');
                }
              }
            }
          }
        } else if (col.type === 'url') {
          if (data && data[col.name] && data[col.name].length > 0) {
            if (!isValidHttpUrl(data[col.name])) {
              return this.translate.instant('Fill in the correct URL link format');
            }
          }
        }
      }
      if (((!col.nullable || (col.nullable && col.type === 'boolean') || (col.nullable && col.type === 'number' && data[col.name] !== undefined && data[col.name] !== null && data[col.name] !== "")) && !col.generated) && col.type !== 'action' && col.name !== 'privacyterm' && (col.visible || (col.admin && this.isAdmin) || (col.superadmin && this.isSuperAdmin))) {
        if (col.hasOwnProperty('validation')) {
          for (let vald of col.validation) {
            if (vald.operator === 'lt') {
              if (col.type === 'datetime') {
                // console.log('data[vald.target]', data[vald.target]);
                // console.log('moment(data[col.name]', data[col.name]);
                // console.log('minutes', moment(data[vald.target]).diff(moment(data[col.name]), 'minutes'));
                if (vald.hasOwnProperty('target')) {
                  if (!(data[col.name] && data[vald.target] && moment(data[vald.target]).diff(moment(data[col.name]), 'minutes') > 0)) {
                    return col.displayName + ' should be less than ' + this.metaFieldSetting[vald.target]['displayName'];
                  }
                }
              } else {
                if (vald.hasOwnProperty('target')) {
                  if (!(data[col.name] !== undefined && data[vald.target] !== undefined && data[col.name] < data[vald.target])) {
                    return col.displayName + ' should be less than ' + this.metaFieldSetting[vald.target]['displayName'];
                  }
                } else if (vald.hasOwnProperty('value')) {
                  if (!(data[col.name] !== undefined && data[col.name] < vald.value)) {
                    return col.displayName + ' should be greater than ' + vald.value;
                  }
                }
              }
            } else if (vald.operator === 'gt') {
              if (col.type === 'datetime') {
                if (vald.hasOwnProperty('target')) {
                  if (!(data[col.name] && data[vald.target] && moment(data[col.name]).diff(moment(data[vald.target]), 'minutes') > 0)) {
                    return col.displayName + ' should be greater than ' + this.metaFieldSetting[vald.target]['displayName'];
                  }
                } else {
                  if (!(data[col.name] && moment(data[col.name]).diff(moment(), 'minutes') > 0)) {
                    return col.displayName + ' should be greater than current date';
                  }
                }
              } else {
                if (vald.hasOwnProperty('target')) {
                  if (!(data[col.name] !== undefined && data[vald.target] !== undefined && data[col.name] > data[vald.target])) {
                    return col.displayName + ' should be greater than ' + this.metaFieldSetting[vald.target]['displayName'];
                  }
                } else if (vald.hasOwnProperty('value')) {
                  if (!(data[col.name] !== undefined && data[col.name] > vald.value)) {
                    return col.displayName + ' should be greater than ' + vald.value;
                  }
                }
              }
            } else if (vald.operator === 'gte') {
              if (col.type === 'datetime') {
                if (vald.hasOwnProperty('target')) {
                  if (!(data[col.name] && data[vald.target] && moment(data[col.name]).diff(moment(data[vald.target]), 'minutes') >= 0)) {
                    return col.displayName + ' should be greater or equal than ' + this.metaFieldSetting[vald.target]['displayName'];
                  }
                } else {
                  if (!(data[col.name] && moment(data[col.name]).diff(moment(), 'minutes') >= 0)) {
                    return col.displayName + ' should be greater or equal than current date';
                  }
                }
              } else {
                if (vald.hasOwnProperty('target')) {
                  if (!(data[col.name] !== undefined && data[vald.target] !== undefined && data[col.name] >= data[vald.target])) {
                    return col.displayName + ' should be greater or equal than ' + this.metaFieldSetting[vald.target]['displayName'];
                  }
                } else if (vald.hasOwnProperty('value')) {
                  if (!(data[col.name] !== undefined && data[col.name] >= vald.value)) {
                    return col.displayName + ' should be greater or equal than ' + vald.value;
                  }
                }
              }
            } else if (vald.operator === 'eq') {
              if (vald.hasOwnProperty('target')) {
                if (data[col.name] !== data[vald.target]) {
                  if (vald.hasOwnProperty('value')) {
                    if (data[col.name] !== vald.value) {
                      return col.displayName + ' should be equal to ' + this.metaFieldSetting[vald.target]['displayName'];
                    }
                  } else {
                    return col.displayName + ' should be equal to ' + this.metaFieldSetting[vald.target]['displayName'];
                  }
                }
              } else if (vald.hasOwnProperty('value')) {
                if (data[col.name] !== vald.value) {
                  return col.displayName + ' should be equal to ' + vald.value;
                }
              }
            } else if (vald.operator === 'url') {
              if (!this.isUrlValid(data[col.name])) {
                return col.displayName + ' should be in URL format.';
              }
            }
          }
        }
      }
      if (this.data.dataType === 'schedule/event') {

        if (col.type === 'datetime' && col.name === 'startdate') {
          if (data['eventType'] && data['eventType'] !== 'blueprint') {
            if (!(data[col.name] && moment(data[col.name]).diff(moment(), 'minutes') > 0)) {
              if (this.originalData && (this.originalData[col.name] && moment(this.originalData[col.name]).diff(moment(), 'minutes') < 0) && (this.originalData['enddate'] && moment(this.originalData['enddate']).diff(moment(), 'minutes') > 0)) {
                // do nothing here
              } else {

              }
            }
          }
        }


      }
    }
    return undefined;
  }

  getOrgData(callback) {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getSingleData('organization', this.requestService.orgId, (data, error) => {
        if (error) {
          this.errorMessage = error;
          callback(this.organization);
        }
        this.loading = false;
        if (data && data.results) {
          let orgData = data.results;
          localStorage.setItem('org', JSON.stringify(orgData));
          this.requestService.pageOrganization.next(orgData);
          callback(orgData);
        }
      });
    }
  }
  getDataByName(datatype, name, callback) {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let filters = {
        "foreign_key": { "$eq": this.requestService.orgId.getValue() },
        'project': { '$eq': this.vertical },
        'eventType': { '$eq': 'blueprint' },
        'name': { '$eq': name },
      };
      this.requestService.getDataListSummary(datatype, { page: 1, term: '', perpage: 1, filter: filters, orderBy: 'name', orderDir: 'asc', fieldKeys: ['name'] }, (data, error) => {
        this.loading = false;
        if (error) {
          this.errorMessage = error;
        }
        if (data && data.results) {
          if (data.results.length > 0) {
            callback(true);
            return;
          }
        }
        callback(false);
      });
    }
  }

  public validateAppointment(type, cleanObject) {
    let DateData = { startdate: cleanObject.startdate, enddate:cleanObject.enddate }
    this.requestService.ValidateAppointment(this.data.dataType, DateData, (data, error) => {
      this.loading = false;
      if (error) {
        this.errorMessage = error;
        this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
      }
      if (data) {
        if (data.results.hasAvailability) {
          this.confirmChange(type, cleanObject);
        }
        else {

          let mainMsg = 'This appointment does not adhere to the predefined availabilities. Are you sure you want to add it to the schedule?';

          this.UserconfirmCreateWithNoAvailability(mainMsg, type,cleanObject);



        }

      }
    });
  }



  public saveData(type) {
    this.isSubmitted = true;
    if (this.data.dataType === 'schedule/event') {
      if (type === 'edit') {
        // if (this.data.data.isRecurrence) { // added this to make sure the recurence startdate is same as the event one
        //   this.data.data['recurrence']['startdate'] = this.data.data['startdate'];
        // }
        let startdate: any = new Date(this.data.data['startdate']);

        let currentDate: any = new Date();
        if (startdate - currentDate > 0 && this.data.data['eventType'] === 'occurrence' ) {
          if( JSON.stringify(this.originalData) === JSON.stringify(this.data.data)){
            this.saveContinueData(type);
          }
          else {
            const _title: string = 'Alert';
            const _description: string = this.translate.instant('<div>Are you sure you want to update this appointment?</div> <br> <div>Note: We will send a text message to the patient to notify them of the changes.</div>');
            let alertSetting = {
              overlayClickToClose: false,
              showCloseButton: false,
              messageIsTemplate:true,
              confirmText: this.translate.instant('Confirm'),
              declineText: this.translate.instant('Close'),
            };
            const dialogRef = this.layoutUtilsService.alertActionElement(_title, _description, alertSetting);
            dialogRef.afterClosed().subscribe(res => {
              if (res.action === 'confirmText') {
                this.saveContinueData(type);
              }
            });
          }
        

        }

         else if (this.originalData && this.originalData['isRecurrence'] && this.data.data['isRecurrence'] && ((this.originalData['startdate'] !== this.data.data['startdate']) || !deepEqual(this.originalData['recurrence'], this.data.data['recurrence']))) {
          const _title: string = 'Alert';
          const _description: string = this.translate.instant('This event is recurrent and the update will be applied to all the events in this series');
          let alertSetting = {
            overlayClickToClose: false,
            showCloseButton: false,
            confirmText: this.translate.instant('OK'),
            declineText: this.translate.instant('Cancel'),
          };
          const dialogRef = this.layoutUtilsService.alertActionElement(_title, _description, alertSetting);
          dialogRef.afterClosed().subscribe(res => {
            if (res.action === 'confirmText') {
              this.saveContinueData(type);
            }
          });
        } else {
          this.saveContinueData(type);
        }
      } else {
        if (this.data.data['eventType'] && this.data.data['eventType'] === 'blueprint') {
          if ((this.originalData && this.originalData['name'] && this.originalData['name'] !== this.data.data['name'])) {
            this.getDataByName('schedule/event', this.data.data['name'], (callback) => {
              if (callback) {
                this.layoutUtilsService.showNotification(this.translate.instant('Blueprint name is already in use.'), this.translate.instant('Dismiss'));
              } else {
                this.saveContinueData(type);
              }
            });
          } else {
            this.saveContinueData(type);
          }

        } else {
          if (this.data.data['startdate']) {
            let startdate: any = new Date(this.data.data['startdate']);

            let currentDate: any = new Date();
            if (startdate - currentDate < 0) {
              let mainMsg = 'The date set for this appointment has already passed. Are you sure you want to add it to your schedule?';

              this.UserconfirmCreate(mainMsg, type);

            }
            else {
            this.saveContinueData(type);
            }
          } else {
            this.saveContinueData(type);
          }

        }
      }
    } else {
      this.saveContinueData(type);
    }
  }

  UserconfirmCreate(mainMsg, type) {

    let confirmBtn = 'Confirm';
    let declineBtn = 'Cancel';

    this.handleConfirmChangeModal(mainMsg, confirmBtn, declineBtn, (callbackStatus) => {
      if (callbackStatus !== undefined) {

        if (callbackStatus === true) {
          this.saveContinueData(type);

        }
      }
    });

  }

  UserconfirmCreateWithNoAvailability(mainMsg, type,cleanObject) {

    let confirmBtn = 'Confirm';
    let declineBtn = 'Cancel';

    this.handleConfirmChangeModal(mainMsg, confirmBtn, declineBtn, (callbackStatus) => {
      if (callbackStatus !== undefined) {

        if (callbackStatus === true) {
          this.confirmChange(type,cleanObject);

        }
      }
    });

  }





  public saveContinueData(type) {
    // console.log('saveData', this.data.data);
    // console.log('this.data.modalSetting', this.data.modalSetting);
    // console.log('getCleanObject', this.getCleanObject(this.data.data));
    if (this.data.modalSetting.target === 'self') {
      if (!this.loading) {
        let validateLogic = this.validateLogic(this.data.data);
        if (!validateLogic) {
          if (this.validateObject(this.data.data)) {
            let cleanObject = this.getCleanObject(this.data.data);
            if (this.data.dataType === 'schedule/event') {
              if (this.data.data['eventType'] === 'occurrence') {
                this.validateAppointment(type, cleanObject);
              }
              else {
                this.confirmChange(type, cleanObject);
              }
            }
            else {
              this.confirmChange(type, cleanObject);
            }


          } else {
            this.layoutUtilsService.showNotification(this.translate.instant('You need to set all mandatory fields'), this.translate.instant('Dismiss'));
          }
        } else {
          this.layoutUtilsService.showNotification(this.translate.instant(validateLogic), this.translate.instant('Dismiss'));
        }
      }
    } else if (this.data.modalSetting.target === 'parent') {
      let validateLogic = this.validateLogic(this.data.data);
      if (!validateLogic) {
        if (this.validateObject(this.data.data)) {
          let cleanObject = this.getCleanObject(this.data.data);
          if (this.data.dataType === 'schedule/event') {
            if (this.data.data['eventType'] === 'occurrence') {
              this.validateAppointment(type, cleanObject);
            }
            else {
              this.confirmChange(type, cleanObject);
            }
          }
          else {
            this.confirmChange(type, cleanObject);
          }
        } else {
          this.layoutUtilsService.showNotification(this.translate.instant('You need to select all mandatory fields'), this.translate.instant('Dismiss'));
        }
      } else {
        this.layoutUtilsService.showNotification(this.translate.instant(validateLogic), this.translate.instant('Dismiss'));
      }
    }
  }
  public saveContinueDBData(type, cleanObject) {
    this.loading = true;
    //  this.loaderService.display(true);
    // let useOrgId = false;
    // if (this.data.modalSetting.hasOwnProperty('useOrgId')) {
    //   useOrgId = this.data.modalSetting['useOrgId'];
    // }
    this.errorMessage = '';
    this.requestService.saveData(this.data.dataType, cleanObject, (data, error) => {
      this.loading = false;
      if (error) {
        this.errorMessage = error;
        this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
      }
      if (data) {
        if (type === 'create') {
          // if (this.data.dataType === 'user') {
          //   this.requestService.logTrackEvent(new EventTrack(EventType.SIGNUP, undefined, undefined, undefined, undefined, undefined, data.results._id, data.results.name, data.results.email), (res, err) => {
          //     this.returnRefresh();
          //   });
          // } else {
          this.returnRefresh();
          // }
          this.layoutUtilsService.showNotification(this.data.dataTypeSingleTitle + ' ' + this.translate.instant('created Successfully'), this.translate.instant('Dismiss'));
        } else if (type === 'edit') {
          this.layoutUtilsService.showNotification(this.data.dataTypeSingleTitle + ' ' + this.translate.instant('edited Successfully'), this.translate.instant('Dismiss'));
          this.returnRefresh();
        }
      }
    });
  }
  returnRefresh() {
    if (this.data.modalSetting.refreshOrganization || this.data.dataType === 'room' || this.data.dataType === 'role') {
      this.getOrgData((orgData) => {
        this.closeModal({ action: 'refresh' });
      });
    } else {
      this.closeModal({ action: 'refresh' });
    }
  }
  closeModal(data): void {
    this.dialogRef.close(data);
  }
  public toggleClick(action, target, data) {
    if (target === 'parent') {
      if (this.validateObject(this.data.data)) {
        this.closeModal({ action: action, data: this.data.data, value: data });
      } else {
        this.layoutUtilsService.showNotification(this.translate.instant('You need to select all mandatory fields'), this.translate.instant('Dismiss'));
      }
    } else {
      //console.log('toggleClick Self', action, target, data);
      if (action === 'close') {
        this.closeModal(undefined);
      }
    }
  }
  private getEmptyObject() {
    let newObj = {};
    for (let col of this.data.modalSetting.fields) {
      if ((col.editable || !col.generated) && col.type !== 'action') {
        if (col.type === 'reference') {
          if (col.reference.kind === 'multiple') {
            if (col.name === 'resources' || col.name === 'role') {
              newObj[col.name] = [{ _id: '', name: '' }];
            } else {
              newObj[col.name] = [];
            }
          } else {
            newObj[col.name] = { _id: '', name: '' };
          }
        } else if (col.type === 'boolean') {
          if (col.name === 'isPasswordRequired') {
            newObj[col.name] = this.isPasswordOrgRequired;
          } else if (this.data.action === 'create-room-lobby' && (col.name === 'main' || col.name === 'emptyRoom' || col.name === 'showbg')) {
            newObj[col.name] = true;
          } else {
            newObj[col.name] = false;
          }
        } else if (col.type === 'color') {
          newObj[col.name] = '#ffffff';
        } else if (col.type === 'picturearray') {
          newObj[col.name] = [];
        } else if (col.type === 'maparray' || col.type === 'tags') {
          newObj[col.name] = [];
        } else if (col.type === 'json') {
          newObj[col.name] = {};
        } else if (col.type === 'datetime' || col.type === 'date') {
          if (col.name === 'dob') {
            newObj[col.name] = '';
          } else {
            newObj[col.name] = moment.utc().local().format('YYYY-MM-DDTHH:mm:ss');
          }
        } else if (col.type === 'number') {
          newObj[col.name] = undefined;
        } else if (col.type === 'enum') {
          if (col.name === "meetingType") {
            newObj[col.name] = [];
          }
          else if (col.multiple) {
            newObj[col.name] = [];
          } else {
            newObj[col.name] = '';
          }

        } else if (col.type === 'objectjson') {
          if (col.name === 'attendees' && this.data.dataType === 'event') {
            newObj[col.name] = [];
          }
        } else {
          newObj[col.name] = '';
        }
        if (this.data.modalSetting.hasOwnProperty('customSettings')) {
          if (this.data.modalSetting.customSettings.hasOwnProperty(col.name) && this.data.modalSetting.customSettings[col.name].hasOwnProperty('value')) {
            newObj[col.name] = this.data.modalSetting.customSettings[col.name].value;
          }
        }
      }
    }
    // console.log('newObj', newObj);
    return newObj;
  }
  setRuleText() {
    // console.log('this.returnData', this.returnData);
    try {
      let ruleText = this.getRecurrenceDatas(this.data.data.recurrence);
      this.ruleText = ruleText.humantext;
      this.data.data['recurrence']['tzid'] = ruleText['tzid'];
      this.data.data['recurrence']['humantext'] = ruleText.humantext;
      this.data.data['recurrence']['localdate'] = ruleText['localdate'];
      this.data.data['recurrence']['slocaldate'] = ruleText['slocaldate'];
      this.data.data['recurrence']['enddate'] = ruleText['enddate'];

    } catch (e) {
      // error
      console.log('setRuleText', e);
    }
  }
  editRecurrence() {
    // console.log('this.data.data.recurrence', this.data.data.recurrence);
    const dialogRef = this.dialog.open(RecurrenceDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Recurrence'),
        data: this.data.data.recurrence || {},
        startdate: this.data.data.startdate,
        enddate: this.data.data.enddate,
        fields: this.metaFieldSetting['recurrence'].fields,
        overWrite: this.metaFieldSetting['overWrite'],
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // let startdate = new Date(this.data.data.startdate);
        // let startdateUTC = new Date(Date.UTC(startdate.getFullYear(), startdate.getMonth(), startdate.getDate(), startdate.getHours(), startdate.getMinutes(), 0));
        // result['startdate'] = startdateUTC; // added this to make sure we always use the start date from event
        // let dtstart = moment(this.data.data.startdate).utc().toDate();
        // dtstart.setMinutes(dtstart.getMinutes() - dtstart.getTimezoneOffset());
        result['startdate'] = moment(this.data.data.startdate).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        result['enddate'] = moment(this.data.data.enddate).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

        this.data.data['recurrence'] = result;
        this.setRuleText();
      }
    });
  }
  confirmChange(type, cleanObject) {
    if (this.data.dataType === 'schedule/event' && type === 'edit' && this.data.data['eventType'] !== 'blueprint') {
      let ruleOriginalText = '';
      let ruleText = '';
      try {
        ruleOriginalText = this.getRecurrenceDatas(this.originalData.recurrence).humantext;
        ruleText = this.getRecurrenceDatas(this.data.data.recurrence).humantext;
      } catch (e) {
        // console.log(e);
      }
      let isRecurrenceOriginal = this.originalData.isRecurrence;
      let isRecurrence = this.data.data.isRecurrence;
      if (ruleOriginalText !== ruleText || (isRecurrenceOriginal === true && isRecurrence === false) || (isRecurrence && (moment(this.originalData.startdate).diff(moment(this.data.data.startdate), 'minutes') != 0 || moment(this.originalData.enddate).diff(moment(this.data.data.enddate), 'minutes') != 0))) {
        let mainMsg = 'You are changing the recurrence of the current event. How do you want to proceed?';
        let confirmBtn = 'Deleting future events and creating a new event';
        let declineBtn = 'Keeping existing events and creating a new event';
        this.handleConfirmChangeModal(mainMsg, confirmBtn, declineBtn, (callbackStatus) => {
          if (callbackStatus !== undefined) {
            if (callbackStatus === true) {
              cleanObject['overWrite'] = '1';
            } else if (callbackStatus === false) {
              cleanObject['overWrite'] = '3';
            }
            if (this.data.modalSetting.target === 'self') {
              this.saveContinueDBData(type, cleanObject);
            } else if (this.data.modalSetting.target === 'parent') {
              this.closeModal({ action: type, dataType: this.data.dataType, dataTypeTitle: this.data.dataTypeSingleTitle, modalSetting: this.data.modalSetting, data: cleanObject });
            }
          }
        });
      } else {
        if (this.data.modalSetting.target === 'self') {
          this.saveContinueDBData(type, cleanObject);
        } else if (this.data.modalSetting.target === 'parent') {
          this.closeModal({ action: type, dataType: this.data.dataType, dataTypeTitle: this.data.dataTypeSingleTitle, modalSetting: this.data.modalSetting, data: cleanObject, originalData: this.oldData });
        }
      }
    } else {
      if (this.data.modalSetting.target === 'self') {
        this.saveContinueDBData(type, cleanObject);
      } else if (this.data.modalSetting.target === 'parent') {
        this.closeModal({ action: type, dataType: this.data.dataType, dataTypeTitle: this.data.dataTypeSingleTitle, modalSetting: this.data.modalSetting, data: cleanObject});
      }
    }
  }
  private handleConfirmChangeModal(mainMsg: string, confirmBtn: string, declineBtn: string, callback: (dataResponse: boolean) => void, smallMsg: string = undefined, messageIsTemplate: boolean = false) {
    let alertSetting = {};
    alertSetting['overlayClickToClose'] = false;
    alertSetting['showCloseButton'] = false;
    alertSetting['smallMsg'] = smallMsg;
    alertSetting['confirmText'] = confirmBtn;
    alertSetting['declineText'] = declineBtn;
    alertSetting['messageIsTemplate'] = messageIsTemplate;

    const dialogRef = this.layoutUtilsService.alertActionElement(this.translate.instant('Confirm Changes'), this.translate.instant(mainMsg), alertSetting);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.action === 'confirmText') {
          callback(true);
        } else if (result.action === 'declineText') {
          callback(false);
        }
      } else {
        callback(undefined);
      }
    });
  }
  private isUrlValid(url) {
    var re = /^((http[s]?):\/)+\/?([^:\/\s]?)((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$/;
    return re.test(String(url).toLowerCase());
  }
  private isEmailValid(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  public addPictures(id, url) {
    if (this.data.data.hasOwnProperty(id)) {
      this.data.data[id].push({ url: url, type: 'link', link: "", thumbnail: getOtherImage('', url, '_thumbnail') });
    } else {
      this.data.data[id] = [{ url: url, type: 'link', link: "", thumbnail: getOtherImage('', url, '_thumbnail') }];
    }
  }
  public setPictureArrayType(idx, id, val) {
    this.data.data[id][idx]['type'] = val;
    this.data.data[id][idx]['link'] = '';
    this.data.data[id][idx]['linkDisplay'] = '';
  }
  public setMapArrayType(idx, id, val) {
    this.data.data[id][idx]['type'] = val;
    this.data.data[id][idx]['link'] = '';
  }
  public setMapArrayShape(idx, id, val) {
    this.data.data[id][idx]['shape'] = val;
  }
  public selectPictureLinkType(idx, id, type) {
    if (type === 'tile') {
      // this.editTile(idx, id);
    } else if (type === 'room') {
      this.editCustomSelectDialog('room', 'Room', idx, id);
    }
  }
  editCustomSelectDialog(dataType, dataTypeTitle, idx, id) {
    let filterData: any = {
      '$and': [
        { 'organizationId._id': { '$eq': this.requestService.orgId } }
      ]
    };
    const dialogRef = this.dialog.open(CustomSelectDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Room'),
        dataType: dataType,
        dataTypeTitle: dataTypeTitle,
        filters: filterData,
        data: this.data.data[id][idx]['link'],
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.hasOwnProperty('_id')) {
          this.data.data[id][idx]['link'] = result['_id'];
          this.data.data[id][idx]['linkDisplay'] = result['text'];
        } else {
          this.data.data[id][idx]['link'] = '';
          this.data.data[id][idx]['linkDisplay'] = '';
        }
      }
    });
  }
  public addArrayMapOption(id) {
    if (!this.data.data.hasOwnProperty(id)) {
      this.data.data[id] = [];
    }
    this.data.data[id].push({ coords: '', shape: 'rect', type: 'link', link: '', title: 'New Title', target: '_blank' });

  }
  public deleteArrayMap(id, index) {
    this.data.data[id].splice(index, 1);
  }
  public deletePictures(id, index) {
    this.data.data[id].splice(index, 1);
  }

  editPictureWallImages($event, id) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    const dialogRef = this.dialog.open(ModalGalleryDialogComponent, {
      width: '1600px',
      disableClose: false,
      autoFocus: false,
      data: {
        title: this.translate.instant('Edit Billboard Images Uploaded'),
        galleryType: this.data.dataType + '-' + id + '-art',
        targetId: this.requestService.orgId,
        data: [],
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (Array.isArray(result)) {
          for (let itm of result) {
            this.addPictures(id, itm.imageUrl);
          }
        } else {
          this.addPictures(id, result);
        }
        // do nothing
      }
    });
  }
  /**
  *  @param target: trigger event
  *
  *  trigger read files browsed files
  */
  onBrowseFiles(id, target: any): void {
    // this.readFiles(id, target.files);
    // if (target.files.length > 1) {
    // const dialogRef = this.dialog.open(MultipleModalDialogComponent, {
    //   width: '100vw',
    //   disableClose: false,
    //   autoFocus: false,
    //   data: {
    //     title: this.translate.instant('Multiple') + ' ' + this.translate.instant('Images') + ' ' + this.translate.instant('Upload'),
    //     path: 'banner/image/upload/' + this.requestService.orgId,
    //     target: target,
    //     allowedExtensions: this.allowedExtensions,
    //     allowedAcceptExtensions: this.allowedAcceptExtensions,
    //     isImageUpload: true,
    //     addName: true
    //   }
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   if (this.fileselect) {
    //     this.fileselect.nativeElement.value = "";
    //   }
    //   if (result) {
    //     console.log(result);
    //     for (let itm of result) {
    //       this.addPictures(id, itm.uploaded.link);
    //     }
    //   }
    // });
    // } else {
    // 	this.readFiles(id, target.files);
    // }
  }

  /**
   *  @param files: list of browsed files
   *  @param index: iterator over browsed images
   *
   *  read files browsed by user
   */
  readFiles(id, files, index = 0): void {
    // let reader = new FileReader();
    if (index in files) {
      let currentFile = { error: false, text: files[index].name, id: files[index].id, originalFile: files[index], source_url: null, extension: '' };
      let fileExt = files[index].name.split('.').pop();
      currentFile.extension = fileExt.toLowerCase();
      // this.imageExtension = fileExt.toLowerCase();
      const max_size = 5000000;
      if (files[index].size > max_size) {
        this.layoutUtilsService.showNotification(this.translate.instant('Maximum size allowed is') + ' ' + max_size / 1000000 + 'MB', this.translate.instant('Dismiss'));
      } else if (this.allowedExtensions.indexOf(fileExt.toLowerCase()) === -1) {
        currentFile.error = true;
      } else {
        this.loaderService.display(true);
        // this.requestService.onUploadPictureByBanner(currentFile)
        //   .subscribe(
        //     (results: any) => {
        //       //console.log('results', results);
        //       if (results['status']) {
        //         currentFile.source_url = results['results'].link;
        //         this.addPictures(id, results['results'].link);
        //         this.layoutUtilsService.showNotification(this.translate.instant('Successfully Uploaded'), this.translate.instant('Dismiss'));
        //       } else {
        //         currentFile.error = true;
        //         this.layoutUtilsService.showNotification(results['message'], this.translate.instant('Dismiss'));
        //       }
        //       // this.myInputVariable.nativeElement.value = "";
        //       this.loaderService.display(false);
        //       this.changeDetectorRefs.detectChanges();
        //       // this.currentFile = currentFile;
        //     },
        //     error => {
        //       //console.log('Error uploading file.', error);
        //       this.loaderService.display(false);
        //       currentFile.error = true;
        //       this.layoutUtilsService.showNotification(this.translate.instant('Error uploading file.'), this.translate.instant('Dismiss'));
        //       this.changeDetectorRefs.detectChanges();
        //     }
        //   );
      }
    } else {
      this.changeDetectorRefs.detectChanges();
    }
    if (this.fileselect) {
      this.fileselect.nativeElement.value = "";
    }

  }
  readFile(file, reader, callback): void {
    reader.onload = () => {
      callback(reader.result);
    }
    reader.readAsDataURL(file);
  }
  getRecurrenceDatas(recurObj) {
    let response = { humantext: '', all: undefined, rule: undefined };
    if (!recurObj) {
      return response;
    } else {
      let pattern = "";
      let patternView = "";
      let count = 0;
      switch (recurObj["repeats"]) {
        case "minutely":
          pattern = "FREQ=MINUTELY;INTERVAL=" + recurObj["interval"];
          break;
        case "hourly":
          pattern = "FREQ=HOURLY;INTERVAL=" + recurObj["interval"];
          break;
        case "daily":
          pattern = "FREQ=DAILY;INTERVAL=" + recurObj["interval"];
          break;
        case "weekly":
          pattern = "FREQ=WEEKLY;INTERVAL=" + recurObj["interval"] + ";BYDAY=" + recurObj["byweekday"].toString();
          break;
        case "monthly":
          pattern = "FREQ=MONTHLY;INTERVAL=" + recurObj["interval"];
          if (recurObj["monthlyType"] && recurObj["monthlyType"] == "dayofmonth") {
            pattern += ";BYMONTHDAY=" + recurObj["bymonthday"].toString();
          } else {
            pattern += ";BYSETPOS=" + recurObj["bysetpos"] + ";BYDAY=+" + recurObj["bysetpos"] + recurObj["byday"].toString();
          }
          break;
        case "yearly":
          pattern = "FREQ=YEARLY;"
          if (recurObj["yearlyType"] && recurObj["yearlyType"] == "dayofmonth") {
            pattern += "BYMONTH=" + recurObj["bymonth"] + ";BYMONTHDAY=" + recurObj["bymonthday"].toString();
          } else {
            pattern += "BYDAY=+" + recurObj["bysetpos"] + recurObj["byday"] + ";BYSETPOS=" + recurObj["bysetpos"] + ";BYMONTH=" + recurObj["bymonth"].toString();
          }
          break;
        default:
          pattern = "";
      }
      patternView = pattern;
      if (recurObj["end"] && recurObj["end"] == "after") {
        pattern += ";COUNT=" + recurObj["count"];
        patternView += ";COUNT=" + recurObj["count"];
        count = recurObj["count"];
      }
      if (recurObj["tzid"]) {
        pattern += ";TZID=" + recurObj["tzid"];
      } else {
        pattern += ";TZID=" + this.getUserTimeZone();
      }
      if (recurObj["end"] && recurObj["end"] == "date") {
        pattern += ";UNTIL=" + moment(recurObj["until"]).format('YYYYMMDDTHHmmss');
        patternView += ";UNTIL=" + moment(recurObj["until"]).format('YYYYMMDDTHHmmss');
      }
      // console.log('pattern', pattern);
      let options = rrule.RRule.parseString(pattern);
      let optionsView = rrule.RRule.parseString(patternView);
      let startdate = new Date(recurObj["startdate"]);
      let enddate = new Date(recurObj["enddate"]);
      const utcStartDate = new Date(startdate.getTime() - startdate.getTimezoneOffset() * 60000);
      const utcEndDate = new Date(enddate.getTime() - enddate.getTimezoneOffset() * 60000);
      const utcMoment = moment.utc(recurObj["startdate"]);
      const localMoment = utcMoment.local();
      const formattedLocalTime = localMoment.format('YYYY-MM-DD hh:mm A');
      options.dtstart = utcStartDate;
      optionsView.dtstart = utcStartDate;
      if (count && count != 0) {
        options.count = count;
        optionsView.count = count;
      } else {
        // options.until = new Date(recurObj["until"]);
        //options.until = moment(recurObj['until']).utc().toDate();
        //optionsView.until = moment(recurObj['until']).toDate();
      }
      if (recurObj["tzid"]) {
        options.tzid = recurObj["tzid"];
      } else {
        options.tzid = this.getUserTimeZone();
      }
      var rule = new rrule.RRule(options);
      var ruleView = new rrule.RRule(optionsView);
      // return rule.toText();
      var all = rule.all();
      response["enddate"] = enddate;
      response["tzid"] = recurObj["tzid"];
      response["localdate"] = utcStartDate;
      response["slocaldate"] = formattedLocalTime;
      response["humantext"] = ruleView.toText();
      // response["rule"] = rule;
      let allData = all.map((occurrence: Date) => {
        occurrence = new Date(occurrence.getTime() + occurrence.getTimezoneOffset() * 60000);
        return occurrence;
      });
      if (allData.length > 0) {
        response["enddate"] = allData[allData.length - 1];
      }
    }
    return response;
  };

  getUserTimeZone() {
    // Create a new date object with the current date and time
    const now = new Date();

    // Get the time zone from the date object using the Intl.DateTimeFormat API
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return timeZone;
  }
}
