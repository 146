import { Component, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestService, LayoutUtilsService, LoaderService, StoreService } from '../../../shared/services';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import { EventData } from '../../../shared/modules/calendar/interface/event-data';
import { CustomSelectCreateAutocompleteComponent } from '../custom-select-create-autocomplete/custom-select-create-autocomplete.component';
import { TranslateService } from '@ngx-translate/core';
import { ModalDialogComponent } from '../../../shared/components/custom-dialog/custom-dialog.component';
import { ModalViewEventDialogComponent } from '../../../shared/modules/calendar/view-event-dialog/view-event-dialog.component';
import * as moment from 'moment';
import { CalendarComponent } from '../../modules/calendar';
import { formatDate } from '@angular/common';
import { AvailabilityListDialogComponent } from '../../../pages/schedular/availability-list-dialog/availability-list-dialog.component';
import { table } from 'console';



@Component({
  selector: 'app-custom-scheduler',
  templateUrl: './custom-scheduler.component.html',
  styleUrls: ['./custom-scheduler.component.scss']
})
export class CustomSchedulerComponent implements OnInit {
  public subscriptions: any[] = <any>[];
  public selectedUser: any;
  public organization = undefined;
  public isAdmin: boolean = false;
  public isSuperAdmin: boolean = false;
  public originalTableSetting: any = undefined;
  public metaFieldSetting = undefined;
  public tableSetting: any = undefined;
  public settingOrgObject = undefined;
  public loading: boolean = false;
  public anchorDate = new Date();
  public actDateFormat: string = 'EEEE, MMMM dd';
  public actFullDate: string = formatDate(this.anchorDate, this.actDateFormat, 'en');

  public permissions = {
    canViewEvent: false,
    canAddEvent: false,
    canEditEvent: false,
    canDeleteEvent: false,
    canCreateEvent: false
  }
  public meetingType: any = {
    '1': 'Online',
    '2': 'In Person',
  }

  public hasRolesOrgRequired: boolean = false;
  public viewDesignMode: string = 'calendar_month';// 'format_list_bulleted';
  public vertical = 'patientdashboard';//environment.orgType;
  // public title = environment.serverName;
  public subTitle = environment.subServerName;
  public dataType: string = 'schedule/event';
  public dataTypeDisplay: string = this.translate.instant('Appointment');
  public eventsActionsType: any = {
    'occurrence': { displayName: 'Appointment', color: '#f5f5f5' },
    'blueprint': { displayName: 'Availability', color: '#f5f5f5' },
  }
  public eventsActions: any[] = [

    {
      displayName: "New Appointment",
      value: "occurrence"
    },
    {
      displayName: "My Availabilities",
      value: "Availabilities"
    }
  ];
  public dayAvailabilityActions: any[] = [

    {
      displayName: "New Appointment",
      value: "newappointment"
    },

    {
      displayName: "Remove Availability",
      value: "removeAvailabilty"
    }
  ];

  public currentDay: any = undefined;
  public dataSource: any = { events: <EventData[]>[], skelleton: <EventData[]>[] };
  public dataArray: EventData[] = [];
  public dataSkelletonArray: EventData[] = [];
  public dataListAll: any[] = [];
  public dataListBlueprintAll: any[] = [];
  public errorMessage: string = '';
  public filterData = undefined;
  public selectedFilter = undefined;
  public selectedDay = undefined;
  public currentWeek: any = undefined;
  public selectedDayEvents = undefined;
  public selectedDaysAnalyzeEvents = [];
  public disableEventBlueprints = true;
  defaultSchedularColors: any = {
    default: 'white',
    selectedDay: '#7b80a536',
    availability: '#d5f0ff',
    eventBg: '#f5f5f5',
    eventFont: 'inherit',
    rightEventBorder: '#428bca',
    rightEventBg: 'white',
    defaultEventBgColor: '',
  }

  public blueprintDurationBreak: any = {
    name: 'maximum-appointment-time',
    durations: {
      1: 15,
      2: 30,
      3: 60,
      4: 90,
      5: 120
    },
    durationUnit: 'minutes'
  };



  @Input() personal: boolean = false;
  @Input() dateFormat: string = 'dd-MM-yyyy';
  @ViewChild('customViewEventTemplate') public customViewEventTemplate: TemplateRef<any>;
  @ViewChild('blueprintList') public blueprintList: CustomSelectCreateAutocompleteComponent;
  @ViewChild('calendarComp') public calendarComp: CalendarComponent;

  constructor(private router: Router, private loaderService: LoaderService,
    private requestService: RequestService, private storeService: StoreService,
    public dialog: MatDialog, private translate: TranslateService,
    private layoutUtilsService: LayoutUtilsService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          // TODO: check if user is admin
          this.isAdmin = this.requestService.isUserRoleAdmin();
          // TODO: check if user is super admin
          this.isSuperAdmin = this.requestService.isUserRoleSuperAdmin();
          this.filterData = this.getBlueprintCustomFilter();
          this.buildSetting();
          //console.log(moment.localeData().longDateFormat('L'));
        }
      })
    );

    this.subscriptions.push(
      // TODO: check pageOrganization
      this.requestService.pageOrganization.subscribe((data) => {
        if (data) {
          this.organization = data;
          this.hasRolesOrgRequired = (data.roleCount && data.roleCount > 0);
          if (data.hasOwnProperty('settings')) {
            this.settingOrgObject = data.settings;
            if (this.settingOrgObject && this.settingOrgObject.web && this.settingOrgObject.web.page && this.settingOrgObject['web']['page']['enableScheduler']) {
            } else {
              this.router.navigate(['/rooms/list'], { relativeTo: this.activatedRoute });
            }
          }
        }
      })
    );
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  goTo(path) {
    this.router.navigate([path]);
  }
  private buildSetting() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getMetaData(this.dataType, undefined, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error: ' + error, 'Dismiss');
        }
        this.loading = false;
        if (data) {
          this.originalTableSetting = Object.assign({}, data.results);
          this.metaFieldSetting = this.buildMetaSetting(data.results, parent = undefined);
          //this.eventsActions = this.metaFieldSetting['eventType'].enum;
          const copiedItem = JSON.parse(JSON.stringify(this.originalTableSetting));
          this.tableSetting = this.getSetting(copiedItem, this.getCustomFilter());
          if (this.isAdmin) {
            this.permissions.canAddEvent = true;
            this.permissions.canEditEvent = true;
            this.permissions.canDeleteEvent = true;
          }
          this.permissions.canViewEvent = true;
          this.loadDBData();
        } else {
          console.log(this.translate.instant('Something is Wrong'));
        }
      });
    }
  }
  buildMetaSetting(data, parent = undefined) {
    let dataObject = {};
    for (let col of data.fields) {
      if ((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') {
        if (parent) {
          col['inputName'] = parent + col['name'];
        }
        dataObject[col.name] = col;
      } else if (col.type === 'object') {
        dataObject[col.name] = this.buildMetaSetting(col);
      }
      else if (col.type === 'table') {
        dataObject[col.name] = col;
      }
    }
    return dataObject;
  }

  dayShift(event) {
    if (this.currentWeek !== event.currentWeek) {
      this.currentWeek = event.currentWeek;
    }
    if (!this.currentDay || (event.currentDay && this.currentDay && (this.currentDay.month != event.currentDay.month || this.currentDay.year != event.currentDay.year))) {
      this.currentDay = event.currentDay;
      if (event.status === 'change') {
        this.loadDBData();
      }
    }
    // console.log('dayShift', event);
    if (event.selectedDay) {
      let day = JSON.parse(JSON.stringify(event.selectedDay));
      this.selectedDay = day;
      if (day.date) {
        setTimeout(() => {
          this.selectedDayEvents = this.setUpcoming(day);
          this.anchorDate = new Date(day.date);
          this.actFullDate = formatDate(this.anchorDate, this.actDateFormat, 'en', '+0000');
        }, 300);
      }
    }
  }

  removeSelectedAvailability(day) {
    if (day.availability) {
      if (day.availability.length > 0) {
        for (let itm of day.availability) {
          let removedDates = [];
          let removedDate = moment(itm.startdate);
          if (itm.hasOwnProperty("removed-blueprints-dates")) {
            if (itm["removed-blueprints-dates"].length > 0) {
              removedDates = itm["removed-blueprints-dates"]
            }

          }
          removedDates.push(removedDate);
          let obj = { _id: itm._id, "removed-blueprints-dates": removedDates }

          this.saveRemovedblueprint(this.dataType, obj);
        }
      }
    }
    else {
      let removedDates = [];
      let removedDate = moment(day.startdate);
      if (day.hasOwnProperty("removed-blueprints-dates") && Array.isArray(day["removed-blueprints-dates"])) {
        removedDates = day["removed-blueprints-dates"]
      }
      removedDates.push(removedDate);
      let obj = { _id: day._id, "removed-blueprints-dates": removedDates }

      this.saveRemovedblueprint(this.dataType, obj);


    }
  }


  selectDay(day) {
    if (!this.permissions.canAddEvent) {
      return;
    }

    if (this.selectedDay && this.selectedDay.day == day.day && this.selectedDay.month == day.month && this.selectedDay.year == day.year) {
      if (day.hasOwnProperty("action")) {
        if (day.action === 'newappointment') {
          this.addEvent({ action: 'occurrence', day: day });
        }
        else if (day.action === 'removeAvailabilty') {
          this.removeSelectedAvailability(day);
        }
      }
      else if (day.event) {
        if (day.event.eventType === 'occurrence') {
          this.editEvent(day.event);
        }
      }
      else {
        this.addEvent({ action: 'occurrence', day: day });
      }
    } else {
      this.selectedDay = day;
      this.selectedDayEvents = this.setUpcoming(day);
      this.anchorDate = new Date(day.date);
      this.actFullDate = formatDate(this.anchorDate, this.actDateFormat, 'en');
    }
    //this.addEvent({ action: 'occurrence', day: day });
  }
  setUpcoming(day) {
    let events = day.events;
    if (day.day === this.currentDay.day && day.month === this.currentDay.month && day.year === this.currentDay.year) {
      let currentDate = moment().utc();
      let nearestDate = events.find(date => {
        return currentDate.diff(moment(date.startdate).utc(), 'minutes') <= 0
      });
      if (nearestDate) {
        events = events.map((itm) => {
          if (nearestDate._id === itm._id) {
            itm['upcoming'] = true;
          }
          return itm;
        })
      }
    }
    return events;
  }
  canCreateFromBluePrint(event) {
    let isCurrentUser = false;
    if (event.eventCreator && event.eventCreator.length > 0) {
      isCurrentUser = event.eventCreator.filter((itm) => {
        if (itm.type === 'user' && this.selectedUser._id === itm._id) {
          return true;
        } else if (this.hasRolesOrgRequired && itm.type === 'role' && this.selectedUser.role && this.selectedUser.role.length > 0 && this.selectedUser.role[0]._id === itm._id) {
          return true;
        }
        return false;
      }
      ).length > 0;
    } else {
      isCurrentUser = true;
    }
    return isCurrentUser;
  }
  isAnAttendee(event) {
    let isCurrentUser = false;
    if (event.opentoall) {
      isCurrentUser = true;
    } else {
      if (event.attendees && event.attendees.length > 0) {
        isCurrentUser = event.attendees.filter((itm) => {
          if (itm.type === 'user' && this.selectedUser._id === itm._id) {
            return true;
          } else if (this.hasRolesOrgRequired && itm.type === 'role' && this.selectedUser.role && this.selectedUser.role.length > 0 && this.selectedUser.role[0]._id === itm._id) {
            return true;
          }
          return false;
        }
        ).length > 0;
      }
    }
    return isCurrentUser;
  }


  selectEventFromList(day) {
    if (!this.permissions.canAddEvent) {
      return;
    }

    if (day.event) {
      if (day.event.eventType === 'occurrence') {
        const dialogRef = this.dialog.open(ModalViewEventDialogComponent, {
          width: '800px',
          data: {
            title: day.event.patientId.name,
            data: day.event,
            fields: this.tableSetting.fields,
            canEdit: this.permissions.canEditEvent,
            canDelete: this.permissions.canDeleteEvent,
            canCreate: this.permissions.canCreateEvent,
            canOpen: false,
            dateFormat: this.dateFormat,
            contentTemplate: this.customViewEventTemplate,

          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            if (result === 'edit') {
              this.editEvent(day.event);
            } else if (result === 'delete') {
              this.deleteEvent(day.event);
            }
          }
        });
        //this.editEvent(day.event);
      }
    }
  }

  selectEvent(day) {
    if (!this.permissions.canAddEvent) {
      return;
    }
    if (this.selectedDay && this.selectedDay.day == day.day && this.selectedDay.month == day.month && this.selectedDay.year == day.year) {
      if (day.hasOwnProperty("action")) {
        if (day.action === 'newappointment') {
          this.addEvent({ action: 'occurrence', day: day });
        }
        else if (day.action === 'removeAvailabilty') {
          this.removeSelectedAvailability(day.event);
        }
      }
      else if (day.event) {
        if (day.event.eventType === 'occurrence') {
          const dialogRef = this.dialog.open(ModalViewEventDialogComponent, {
            width: '800px',
            data: {
              title: day.event.patientId.name,
              data: day.event,
              fields: this.tableSetting.fields,
              canEdit: this.permissions.canEditEvent,
              canDelete: this.permissions.canDeleteEvent,
              canCreate: this.permissions.canCreateEvent,
              canOpen: false,
              dateFormat: this.dateFormat,
              contentTemplate: this.customViewEventTemplate,

            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              if (result === 'edit') {
                this.editEvent(day.event);
              } else if (result === 'delete') {
                this.deleteEvent(day.event);
              }
            }
          });
          //this.editEvent(day.event);
        }
      }

    } else {
      this.selectedDay = day;
      this.selectedDayEvents = this.setUpcoming(day);
      this.anchorDate = new Date(day.date);
      this.actFullDate = formatDate(this.anchorDate, this.actDateFormat, 'en');
    }
  }
  openRoomEvent(event) {
    if (event.place === '1') {
      this.router.navigate(['/rooms/' + event.room._id + '/event/' + event._id], { relativeTo: this.activatedRoute });
    } else if (event.place === '2' && event.meetingurl !== '') {
      if (event.client_org && event.client_org === this.organization._id) {
        this.router.navigate(['/integratediframe'], { queryParams: { iframeUrl: event.meetingurl } });
      } else {
        window.open(event.meetingurl, "_blank");
      }
    }
  }
  deleteEvent(event) {
    this.confirmDelete(event);
  }
  editEvent(event) {
    let tableSetting = JSON.parse(JSON.stringify(this.tableSetting));

    let title = this.translate.instant('Edit') + ' ';

    tableSetting['customSettings']['startdate'] = {
      visible: false
    };
    tableSetting['customSettings']['enddate'] = {
      visible: false
    };

    if (event.eventType === 'blueprint') {
      title += this.translate.instant('Availability');

      title = this.translate.instant('Edit') + ' ' + this.translate.instant('Blueprint');

      tableSetting['customSettings']['patientId'] = {
        visible: false,
      };

      tableSetting['customSettings']['meetingType'] = {
        multiple: true,
      };

    } else {
      title += this.translate.instant('Appointment');
      tableSetting['customSettings']['isRecurrence'] = {
        visible: false,
      };
      tableSetting['customSettings']['allday'] = {
        disabled: true,
      };

      tableSetting['customSettings']['name'] = {
        visible: false,
        value: "Appointment"
      };

      tableSetting['customSettings']['patientId'] = {
        disabled: true,

      };

      tableSetting['customSettings']['meetingType'] = {
        multiple: false,
      };

      tableSetting['customSettings']['maximum-appointment-time'] = {
        visible: false,

      };
      tableSetting['customSettings']['app-change-cancelation-policy'] = {
        visible: false,
      };
      let startdate: any = new Date(event.startdate);

      let currentDate: any = new Date();
      if (startdate - currentDate < 0) {
        let mainMsg = '<div>This appointment has already passed.</div> <br> <div>Are you sure you want to update it?</div>';

        this.UserconfirmEdit(title, event, tableSetting, mainMsg);

      }
      else {

        this.openEditDialog(title, event, tableSetting);
      }

    }

  }

  openEditDialog(title, event, tableSetting) {

    const dialogRef = this.dialog.open(ModalDialogComponent, {
      width: '800px',
      data: {
        dataType: this.dataType,
        dataTypeTitle: this.translate.instant('Event'),
        title: title,
        data: event,
        modalSetting: tableSetting
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.confirmEdit(result.data, result.originalData);
      }
    });
  }

  public saveRemovedblueprint(type, cleanObject) {
    this.loading = true;

    this.errorMessage = '';
    this.requestService.saveData(this.dataType, cleanObject, (data, error) => {
      this.loading = false;
      if (error) {
        this.errorMessage = error;
        this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
      }
      if (data) {

        this.buildSetting();
        this.layoutUtilsService.showNotification(this.translate.instant('Availability Removed Successfully'), this.translate.instant('Dismiss'));


      }
    });
  }


  triggerAddEvent() {

    let event = { action: "occurrence" };

    this.addEvent(event);

  }

  addEvent(event) {
    if (event.action === 'Availabilities') {

      let tableSetting = JSON.parse(JSON.stringify(this.tableSetting));
      let action = event.action;
      let title = 'Availability List ';
      this.dataTypeDisplay = 'Availability';

      const dialogRef = this.dialog.open(AvailabilityListDialogComponent, {
        width: '80%',
        data: {
          dataType: this.dataType,
          dataTypeTitle: this.dataTypeDisplay,
          title: title,
          data: {},
          modalSetting: tableSetting
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        //if (result) {
        //}
        this.buildSetting();

      });

    }
    else {
      let tableSetting = JSON.parse(JSON.stringify(this.tableSetting));
      let action = event.action;
      // let eventTypeName = this.translate.instant('Create New');
      let title = 'Create New ';

      // tableSetting['customSettings']['attendees'] = {
      //   nullable: false,
      //   value: this.getUserAsAttendee()
      // };
      tableSetting['customSettings']['startdate'] = {
        visible: false
      };
      tableSetting['customSettings']['enddate'] = {
        visible: false
      };

      if (action == 'blueprint') {
        title += 'Availability';
        this.dataTypeDisplay = 'Availability';
        tableSetting['customSettings']['patientId'] = {
          visible: false,
        };

        tableSetting['customSettings']['meetingType'] = {
          multiple: true,
        };


      } else {

        title += 'Appointment';
        this.dataTypeDisplay = 'Appointment';

        tableSetting['customSettings']['isRecurrence'] = {
          visible: false,
        };

        tableSetting['customSettings']['allday'] = {
          disabled: true,
        };

        tableSetting['customSettings']['name'] = {
          visible: false,
          value: "Appointment"
        };

        tableSetting['customSettings']['meetingType'] = {
          multiple: false,
        };

        tableSetting['customSettings']['maximum-appointment-time'] = {
          visible: false,

        };
        tableSetting['customSettings']['app-change-cancelation-policy'] = {
          visible: false,
        };

      }
      if (!event.hasOwnProperty('day')) {
        event['day'] = this.selectedDay;
      }
      if (event.hasOwnProperty('day')) {
        let val = new Date();
        let valEnd = new Date();
        if (event.day.hasOwnProperty('hour')) {
          val = new Date(event.day.year, event.day.month, event.day.day, event.day.hour.split(':')[0], event.day.hour.split(':')[1]);
          valEnd = new Date(event.day.year, event.day.month, event.day.day, event.day.hour.split(':')[0], event.day.hour.split(':')[1]);
          valEnd.setMinutes(valEnd.getMinutes() + 30);
        } else {
          if (event.day.event) {
            val = new Date(event.day.event.startdate);
            valEnd = new Date(event.day.event.startdate);
            //valEnd.setHours(valEnd.getHours() + 1);

            let apptTime = event.day.event['maximum-appointment-time'];

            let apptTimeValue = this.blueprintDurationBreak.durations[apptTime];

            valEnd.setMinutes(valEnd.getMinutes() + apptTimeValue);


          }
          else {
            var currentMinutes = val.getMinutes();
            var roundedMinutes = Math.ceil(currentMinutes / 30) * 30;


            val = new Date(event.day.year, event.day.month, event.day.day, val.getHours(), roundedMinutes);
            valEnd = new Date(event.day.year, event.day.month, event.day.day, valEnd.getHours(), roundedMinutes);
            valEnd.setHours(valEnd.getHours() + 1);
          }

        }

        tableSetting['customSettings']['startdate']['value'] = val;
        tableSetting['customSettings']['enddate']['value'] = valEnd;

      }
      tableSetting['customSettings']['color'] = {
        visible: false,
        value: '#f5f5f5'
      };

      tableSetting['customSettings']['eventType'] = {
        visible: false,
        value: action
      };
      // tableSetting['customSettings']['physician'] = {
      //   visible: false,
      //   value: [{ _id: this.requestService.physicianId.getValue(), name: this.requestService.physicianName }]

      // };
      // console.log('tableSetting', tableSetting);
      const dialogRef = this.dialog.open(ModalDialogComponent, {
        width: '800px',
        data: {
          dataType: this.dataType,
          dataTypeTitle: this.dataTypeDisplay,
          title: title,
          data: {},
          modalSetting: tableSetting
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.confirmCreate(result.data);
        }
      });

    }

  }

  private getSetting(data, filters) {
    let tableSetting = data;
    tableSetting['target'] = 'parent';
    if (filters)
      tableSetting['filters'] = filters;
    tableSetting['customSettings'] = {
      foreign_key: {
        visible: false,
        value: this.requestService.orgId.getValue()
      },
      createdBy: {
        visible: false,
        value: this.selectedUser._id
      },
      project: {
        visible: false,
        value: this.vertical
      },
      physicianId: {
        visible: false,
        value: this.requestService.physicianId.getValue()
      },
    };
    return tableSetting;
  }
  getUserAsAttendee() {
    return [{ _id: this.selectedUser._id, name: this.selectedUser.name, email: this.selectedUser.email, type: 'user', userType: 'lead', host: true }];
  }
  private getCustomFilter() {
    let filters = {
      "foreign_key": { "$eq": this.requestService.orgId.getValue() },
      'project': { '$eq': this.vertical },
      'eventType': { '$ne': 'blueprint' }
    };

    filters["$and"] = [
      // {
      //   "createdBy": { "$eq": this.selectedUser._id }
      // },
      {
        "physicianId": this.requestService.physicianId.getValue()
      }
    ]
    return filters;
  }
  private getBlueprintCustomFilter() {
    let filters = {
      "foreign_key": { "$eq": this.requestService.orgId.getValue() },
      'project': { '$eq': this.vertical },
      'eventType': { '$eq': 'blueprint' }
    };

    filters["$and"] = [
      // {
      //   "createdBy": { "$eq": this.selectedUser._id }
      // },
      {
        "physicianId": this.requestService.physicianId.getValue()
      }
    ]

    return filters;
  }
  selectBlueprint(e) {
    this.selectedFilter = e;
    this.dataSource = { events: [], skelleton: [], notInitChanges: true };
    this.loadDBData(undefined, true);
  }
  handleReturnBlueprint(e) {
    if (e) {
      this.loadDBData();
    }
  }
  getFullDay(currentDay) {
    return new Date(this.currentDay.year, this.currentDay.month, 1);
  }
  loadDBData(focusDay = undefined, goToDayMonth = false) {
    if (!this.loading) {
      this.loading = true;
      //this.loaderService.display(true);

      let currentStartDate = moment().utc();
      let currentEndDate = moment().utc();
      if (this.currentDay) {
        let fullDate = this.getFullDay(this.currentDay);
        currentStartDate = moment(fullDate).utc();
        currentEndDate = moment(fullDate).utc();
      }
      let startFilter = currentStartDate.subtract(2, 'months');
      let endFilter = currentEndDate.add(2, 'months');
      let filters = {
        'foreign_key': { '$eq': this.requestService.orgId.getValue() },
        'project': { '$eq': this.vertical },
        'eventType': { '$ne': 'blueprint' },
        'startdate': { '$gte': startFilter.format('YYYY-MM-DDTHH:mm') },
        'enddate': { '$lt': endFilter.format('YYYY-MM-DDTHH:mm') }
      }
      if (!this.isSuperAdmin) {

        filters["$and"] = [
          // {
          //   "createdBy": { "$eq": this.selectedUser._id }
          // },
          {
            "physicianId": this.requestService.physicianId.getValue()
          }
        ]

        // if (this.selectedUser.role && this.selectedUser.role.length > 0) {
        //   filters["$or"].push({
        //     "$and": [
        //       { "attendees.type": "role" },
        //       { "attendees._id": this.selectedUser.role[0]?._id }
        //     ]
        //   })
        // }
      }
      if (this.selectedFilter && this.selectedFilter !== '') {
        filters['blueprint._id'] = { '$eq': this.selectedFilter };
      }
      let filterObj = { page: 1, perpage: 1000, term: '', orderDir: 'asc', orderBy: 'name', fieldKeys: [], filter: filters };
      this.requestService.getDataList(this.dataType,
        filterObj
        , (data, error) => {
          if (error) {
            this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
            //this.loaderService.display(false);
            this.loading = false;
          }
          if (data) {
            let dataList = data.results || [];
            this.dataListAll = dataList;
            //this.loaderService.display(false);
            this.loading = false;
            this.loadBlueprintDBData(dataList, focusDay, goToDayMonth);

          }
        });
    }
  }
  loadBlueprintDBData(events, focusDay = undefined, goToDayMonth = false) {
    if (!this.loading) {
      this.loading = true;
      //this.loaderService.display(true);
      let filters = this.getBlueprintCustomFilter();

      let filterObj = { page: 1, perpage: 1000, term: '', orderDir: 'asc', orderBy: 'name', fieldKeys: [], filter: filters };
      this.requestService.getDataList(this.dataType,
        filterObj
        , (data, error) => {
          if (error) {
            this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
          }
          if (data) {
            let dataList = data.results || [];
            if (this.personal) {
              dataList = dataList.filter((itm) => {
                if (this.canCreateFromBluePrint(itm)) {
                  return true;
                }
                return false;
              })
            }
            this.dataListBlueprintAll = dataList;
            this.setUpData(events, dataList, focusDay, goToDayMonth);
          }
          //this.loaderService.display(false);
          this.loading = false;
        });
    }
  }
  setUpData(events, skelleton, focusDay = undefined, goToDayMonth = false) {
    this.dataArray = events.map((itm) => {
      if (!itm['color'] || (itm['color'] && (itm['color'] === '' || itm['color'] === '#ffffff'))) {
        if (this.eventsActionsType.hasOwnProperty(itm.eventType)) {
          itm['color'] = this.eventsActionsType[itm.eventType].color;
        } else {
          itm['color'] = '#f5f5f5';
        }
      }
      return itm;
    });
    this.dataSkelletonArray = skelleton.map((itm) => {
      if (!itm['color'] || (itm['color'] && (itm['color'] === '' || itm['color'] === '#ffffff'))) {
        if (this.eventsActionsType.hasOwnProperty(itm.eventType)) {
          itm['color'] = this.eventsActionsType[itm.eventType].color;
        } else {
          itm['color'] = '';
        }
      }
      return itm;
    });


    let targetDay = undefined;
    if (goToDayMonth && skelleton.length > 0) {
      let skelletonDataList = JSON.parse(JSON.stringify(skelleton));
      let diffdate: any = new Date();
      let beforedates = skelletonDataList.filter((d) => {
        let currentDate: any = new Date(d.startdate);
        return currentDate - diffdate < 0;
      });
      let afterdates = skelletonDataList.filter((d) => {
        let currentDate: any = new Date(d.startdate);
        return currentDate - diffdate >= 0;
      });
      if (afterdates.length > 0) {
        targetDay = afterdates[0];
      } else if (beforedates.length > 0) {
        targetDay = beforedates[beforedates.length - 1];
      }
    }
    this.dataSource = { events: this.dataArray, skelleton: this.dataSkelletonArray, focusDay: focusDay, goToDayMonth: goToDayMonth ? targetDay : undefined };
  }
  public confirmCreate(dataToCreate) {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.saveData(this.dataType, dataToCreate, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          let returnResult = data.results;
          if (dataToCreate.eventType === 'blueprint') {
            this.selectBlueprint(returnResult._id);
          } else {
            this.sendNewAppointmentSMS(returnResult);
            this.loadDBData(dataToCreate);
          }
          this.layoutUtilsService.showNotification(this.translate.instant('Event') + ' ' + this.translate.instant('created Successfully'), this.translate.instant('Dismiss'));
        }
      });
    }
  }
  public confirmEdit(dataToUpdate, originalData) {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.saveData(this.dataType, dataToUpdate, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.loadDBData(dataToUpdate);
          if (dataToUpdate.eventType === 'blueprint') {
            this.blueprintList.loadData();
          }
          else {
            this.sendUpdateAppointmentSMS(data.results, originalData);
          }
          this.layoutUtilsService.showNotification(this.translate.instant('Event') + ' ' + this.translate.instant('edited Successfully'), this.translate.instant('Dismiss'));
        }
      });
    }
  }
  confirmDelete(event) {

    this.delete(event);

  }

  UserconfirmEdit(title, event, tableSetting, mainMsg) {

    let confirmBtn = 'Update';
    let declineBtn = 'Cancel';

    this.handleConfirmChangeModal(mainMsg, confirmBtn, declineBtn, (callbackStatus) => {
      if (callbackStatus !== undefined) {

        if (callbackStatus === true) {
          this.openEditDialog(title, event, tableSetting);
        } else if (callbackStatus === false) {

        }
        //this.confirmRealDelete(data);
      }
    });

  }


  private handleConfirmChangeModal(mainMsg: string, confirmBtn: string, declineBtn: string, callback: (dataResponse: boolean) => void, smallMsg: string = undefined) {
    let alertSetting = {};
    alertSetting['overlayClickToClose'] = false;
    alertSetting['showCloseButton'] = false;
    alertSetting['smallMsg'] = smallMsg;
    alertSetting['confirmText'] = confirmBtn;
    alertSetting['declineText'] = declineBtn;
    alertSetting['messageIsTemplate'] = true;


    const dialogRef = this.layoutUtilsService.alertActionElement('', this.translate.instant(mainMsg), alertSetting);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.action === 'confirmText') {
          callback(true);
        } else if (result.action === 'declineText') {
          callback(false);
        }
      } else {
        callback(undefined);
      }
    });
  }
  public delete(event: any) {
    let id = event._id;
    let _description = ''
    if (!this.loading) {
      let dataTypeDisplay = this.dataTypeDisplay;

      if (event.eventType === 'blueprint') {
        dataTypeDisplay = 'Availability';
        _description = this.translate.instant('Are you sure you want to permanently delete this') + ' ' + dataTypeDisplay + '?';
      }
      else {
        dataTypeDisplay = 'Appointment';

        let currentDate: any = new Date();

        let enddate: any = new Date(event.startdate);

        if (enddate - currentDate < 0) {

          _description = 'Are you sure you want to remove this appointment?';


        } else {
          _description = 'Are you sure you want to remove this appointment? Note: We will send a text message to the patient to notify them.';

        }
      }

      const _title: string = "";

      const _waitDesciption: string = this.translate.instant('Deleting') + '...';

      const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
      dialogRef.afterClosed().subscribe(res => {
        if (!res) {
          return;
        }
        this.realDelete(event);
      });
    }
  }
  public confirmRealDelete(data) {
    let dataTypeDisplay = this.dataTypeDisplay;
    if (data.eventType === 'blueprint') {
      dataTypeDisplay = 'Blueprint';
    }
    const _deleteMessage = dataTypeDisplay + ' ' + this.translate.instant('Deleted Successfully') + '.';
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.saveData(this.dataType, data, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.loadDBData();
          this.layoutUtilsService.showNotification(_deleteMessage, this.translate.instant('Dismiss'));
        }
      });
    }
  }
  public realDelete(event: any) {
    let id = event._id;
    let dataTypeDisplay = this.dataTypeDisplay;
    if (event.eventType === 'blueprint') {
      dataTypeDisplay = 'Blueprint';
    }
    const _deleteMessage = dataTypeDisplay + ' ' + this.translate.instant('Deleted Successfully') + '.';
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.deleteSingleData(this.dataType, id, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {

          this.sendDeleteSMS(event);

          this.layoutUtilsService.showNotification(_deleteMessage, this.translate.instant('Dismiss'));
          this.loadDBData();
        }
      });
    }
  }

  public sendDeleteSMS(event) {
    let startdate = moment(event.startdate).format('YYYY-MM-DD');
    let starttime = moment(event.startdate).format('HH:mm');

    let message = "This is to inform you that your appointment with Dr. " +
      this.storeService.get('physicianName') + " on " +
      startdate + " at " +
      starttime +
      ' got cancelled';

    this.sendSMS(event.patientId, message);
  }
  public sendUpdateAppointmentSMS(event, originalData) {

    console.log('data', event);
    console.log('originalData', originalData);

    let startdate = moment(event.startdate).format('DD/MM/YYYY');
    let starttime = moment(event.startdate).format('HH:mm');

    //let enddate = moment(event.enddate).format('YYYY-MM-DD');
    //let endtime = moment(event.enddate).format('HH:mm');

    let oldStartdate = moment(originalData.startdate).format('DD/MM/YYYY');
    let oldStarttime = moment(originalData.startdate).format('HH:mm');

    //let oldEnddate = moment(originalData.enddate).format('YYYY-MM-DD');
    //let oldEndtime = moment(originalData.enddate).format('HH:mm');

    let message = "Your appointment with Dr. " + this.storeService.get('physicianName') + " is now " + this.meetingType[event.meetingType[0]] +
                " on " + startdate + " at " + starttime;

    if ((startdate != oldStartdate && starttime != oldStarttime) || (startdate != oldStartdate && event.meetingType[0] != originalData.meetingType[0]) || ( starttime != oldStarttime && event.meetingType[0] != originalData.meetingType[0]) ) {

      // do nothing

    } else {

      if(startdate != oldStartdate){

        message = "The date of your appointment with Dr. " + this.storeService.get('physicianName') + " was updated to " +
                   startdate + " at " + starttime;

      } else if (starttime != oldStarttime){

        message = "The time of your appointment with Dr. " + this.storeService.get('physicianName') + " was updated to " +
         starttime + " on " + startdate ;

      } else if (event.meetingType != originalData.meetingType){

        message = "Your appointment with Dr. " + this.storeService.get('physicianName') +
         " on " +  startdate  + " at " +  starttime  + " was updated to " +  this.meetingType[event.meetingType[0]]

      }

    }

    console.log(message);

    this.sendSMS(event.patientId, message);
  }

  public sendNewAppointmentSMS(event) {
    let startdate = moment(event.startdate).format('YYYY-MM-DD');
    let starttime = moment(event.startdate).format('HH:mm');

    let message = "This is to confirm your " + this.meetingType[event.meetingType[0]] + " appointment with Dr. " +
      this.storeService.get('physicianName') + " on " +
      startdate + " at " +
      starttime;

    this.sendSMS(event.patientId, message);
  }

  public sendSMS(patientId, message) {

    let messageObj = { message: message };

    this.requestService.sendMessage(patientId._id, messageObj, (data, error) => {
      console.log("message sent", data);

    });
  }

}

