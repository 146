<form [hidden]="hideComponent" [class] = "formClassName" >
  <mat-form-field class="example-full-width" >
    <mat-label></mat-label>
  <input type="text" [value]="dataText" placeholder="{{placeholder}}" aria-label="Number" matInput [matAutocomplete]="auto" (input)="termSearch($event.target.value)" style="width: 100%;font-size: 18px;" (blur)="onBlurMethod($event.target.value)" [formControl]="stateCtrl">
  <mat-icon matSuffix>arrow_drop_down</mat-icon>
    <mat-autocomplete #auto="matAutocomplete" #matAutoComplete [displayWith]="displayFn" (optionSelected)="setAttribute($event.option.value)">
       <mat-option *ngFor="let option of filteredData | async" [value]="option" title="{{option.text}}">
        {{option.text}}
      </mat-option> 
      <!-- <mat-option *ngFor="let itm of filteredData | async" [value]="itm._id">
        <span *ngFor="let itmDn of displayName">{{itm[itmDn]}} </span>
      </mat-option> -->
    </mat-autocomplete>
  </mat-form-field>
</form>

<div id="orgNameContainer" [class]="dataType + 'logo'" [hidden]="!hideComponent || hideLabel" >
  <span *ngIf="dataType == 'physician'">
    Welcome
  </span>
    {{dataText}}
</div>
