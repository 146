<div class="col-xl-12">
	<div class="topRightBtn">
		<mat-icon (click)="addEvent($event)" class="newRow" title="Add New Availability">
			add_circle_outline</mat-icon>
	</div>
	<div *ngIf="dataSource.length > 0 " class="mainTable" style='width:100%;' mat-dialog-content>
		<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 myTable">

			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef style="text-align: center;">{{metaFieldSetting.name.displayName}} </th>
				<td mat-cell *matCellDef="let element"> {{element.name}} </td>
			</ng-container>
		
			<ng-container matColumnDef="humantext">
				<th mat-header-cell *matHeaderCellDef style="text-align: center;">Reccurence</th>
				<td mat-cell *matCellDef="let element" >
					<span *ngIf="element.recurrence">
						between {{ element.startdate | date: "MM-dd-yyyy"}} and {{
							element.recurrence.enddate | date: "MM-dd-yyyy"}}, from {{ element.startdate | date: "hh:mm aa"}} till {{
								element.enddate | date: "hh:mm aa"}} {{ element.recurrence.humantext }}
					</span>
					
						<span *ngIf="element['removed-blueprints-dates'].length >0"> Except:
							<span *ngFor="let removed of element['removed-blueprints-dates']; let i = index">
								<span>
									{{ removed | date: "MM-dd-yyyy hh:mm aa"}} 
								</span>
								<span *ngIf="i < element['removed-blueprints-dates'].length - 1">, </span>
							</span>

						</span>
					</td>
			</ng-container>
			<ng-container matColumnDef="meetingType">
				<th mat-header-cell *matHeaderCellDef style="text-align: center;">Meeting Type</th>
				<td mat-cell *matCellDef="let element" style="text-align: center;">
					<span>
						<app-enum-view [enumValue]="element.meetingType" [enumList]="metaFieldSetting['meetingType'].enum"
							[multiple]="metaFieldSetting['meetingType'].multiple">
						</app-enum-view>
					</span>
				</td>
			</ng-container>
			<ng-container matColumnDef="maximum-appointment-time">
				<th mat-header-cell *matHeaderCellDef style="text-align: center;">{{metaFieldSetting['maximum-appointment-time'].displayName}}</th>
				<td mat-cell *matCellDef="let element" style="text-align: center;">
					<span>
						<app-enum-view [enumValue]="element['maximum-appointment-time']" [enumList]="metaFieldSetting['maximum-appointment-time'].enum"
							[multiple]="metaFieldSetting['maximum-appointment-time'].multiple">
						</app-enum-view>
					</span>
				</td>
			</ng-container>
			<ng-container matColumnDef="app-change-cancelation-policy">
				<th mat-header-cell *matHeaderCellDef style="text-align: center;">{{metaFieldSetting['app-change-cancelation-policy'].displayName}} </th>
				<td mat-cell *matCellDef="let element" style="text-align: center;">
					<span>
						<app-enum-view [enumValue]="element['app-change-cancelation-policy']" [enumList]="metaFieldSetting['app-change-cancelation-policy'].enum"
							[multiple]="metaFieldSetting['app-change-cancelation-policy'].multiple">
						</app-enum-view>
					</span>
				</td>
			</ng-container>
		
			<ng-container matColumnDef="_id" >
				<th mat-header-cell *matHeaderCellDef style="text-align: center;"> </th>
				<td mat-cell *matCellDef="let element" style="text-align:right;padding-right: 20px;">
					<button (click)="editEvent(element)" mat-icon-button color="primary" title="Edit" >
						<mat-icon>edit</mat-icon>
					</button>
					<button (click)="DeleteAvailability(element._id)" mat-icon-button color="primary" title="Delete" style="margin-left: 10px;">
						<mat-icon>delete</mat-icon>
					</button>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

		</table>
	</div>

	<div *ngIf="dataSource.length == 0" [hidden]="loading" class="no-data-message" mat-dialog-actions>
		<span>You don't have any Availability yet.</span>
	</div>

	<div mat-dialog-actions>
		<!-- <span *ngIf="data.modalSetting && !data.modalSetting.hasOwnProperty('footer')"> -->
		<button color="danger" mat-raised-button (click)="closeModal(undefined)">Close</button>
	</div>
</div>